import {Validation} from './Validation';
import {WorkflowSerializer} from './WorkflowSerializer';
import {ValidationStep} from './ValidationStep';
import {ValidationStepSerializer} from './ValidationStepSerializer';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';


export class ValidationSerializer implements EntitySerializer {

    private workflowSerializer = new WorkflowSerializer();
    private validationStepSerializer = new ValidationStepSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['dateCreation', 'date_creation'],
        ['contactSenderId', 'contact_sender_id'],
        ['contactSenderSubscribe', 'contact_sender_subscribe'],
        ['type', 'type'],
        ['locale', 'locale'],
        ['status', 'status'],
        ['launchDate', 'launch_date'],
        ['endDate', 'end_date'],
        ['custom', 'custom']
    ]);


    fromJson(json: any): Validation {
        const validation = new Validation();
        this.mapping.forEach((value, key, map) => {
            validation[key] = json[value];
        });

        /**
         * Création d'un workflow
         */
        if (json['workflow'] !== null && typeof (json['workflow']) !== 'undefined') {
            validation.workflow = this.workflowSerializer.fromJson(json['workflow']);
        }
        /**
         * Création des validationSteps
         */
        const validationSteps = json['validation_steps'];
        if (validationSteps && validationSteps instanceof Array) {
            const vSteps: ValidationStep[] = [];
            validationSteps.forEach(vStep => {
                vSteps.push(this.validationStepSerializer.fromJson(vStep));
            });
            validation.validationSteps = vSteps;
        }

        return validation;
    }

    toApi(validation: Validation): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validation[key];
        });

        return obj;
    }
}
