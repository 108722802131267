import {ContactValidationStep} from './ContactValidationStep';
import {ValidationStepResult} from './ValidationStepResult';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class ValidationStep extends Entity {

    private _finalResult: string;
    private _validationDate: Date;
    private _label: string;
    private _position: number;
    private _step_duration: string;
    private _description: string;
    private _validationStepResults: ValidationStepResult[];
    private _contactValidationStep: ContactValidationStep[];



    constructor() {
        super();
        this.contactValidationStep = [];
    }

    get finalResult(): string {
        return this._finalResult;
    }

    set finalResult(value: string) {
        this._finalResult = value;
    }

    get validationDate(): Date {
        return this._validationDate;
    }

    set validationDate(value: Date) {
        this._validationDate = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get step_duration(): string {
        return this._step_duration;
    }

    set step_duration(value: string) {
        this._step_duration = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get contactValidationStep(): ContactValidationStep[] {
        return this._contactValidationStep;
    }

    set contactValidationStep(value: ContactValidationStep[]) {
        this._contactValidationStep = value;
    }

    get validationStepResults(): ValidationStepResult[] {
        return this._validationStepResults;
    }

    set validationStepResults(value: ValidationStepResult[]) {
        this._validationStepResults = value;
    }

    public findContactValidationStepByPriority(priority: number) {
        if (this.contactValidationStep && this.contactValidationStep instanceof Array) {
            return this.contactValidationStep.find(cvs => cvs.priority === priority);
        } else {
            return null;
        }
    }
}
