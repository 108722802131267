<div class="edw-modal edw-modal--medium edw-rules-status">
    <div class="edw-modal__header">
        <h2>{{'status-computation-rules.modal.text.title' | translate}}</h2>
        <div class="cross" (click)="close()">
            <i class="edw-icon edw-icon-cross"></i>
        </div>
    </div>

    <div class="edw-modal__body">
        <table class="w100">
            <tr>
                <th rowspan="1" class="border-top-left-radius">{{'status-computation-rules.modal.header.text.status' | translate}}</th>
                <th rowspan="1">{{'status-computation-rules.modal.header.text.variable' | translate}}<i></i></th>
                <th rowspan="1" class="border-top-right-radius">{{'status-computation-rules.modal.header.text.mandatory' | translate}}</th>
            </tr>
            <tr>
                <th colspan="1">{{'global.text.named' | translate}}</th>
                <th>
                    <table class="flex column">
                        <td>{{'global.text.name' | translate}}</td>
                    </table>
                </th>
                <th>
                    <table class="flex column">
                        <td>{{'global.yes' | translate}}</td>
                    </table>
                </th>
            </tr>
            <tr>
                <th colspan="1">{{'global.text.identified' | translate}}</th>
                <th>
                    <table class="flex column">
                        <td>{{'global.text.owner' | translate}}</td>
                        <td>{{'global.text.org-unit' | translate}}</td>
                        <td>{{'global.text.description' | translate}}</td>
                    </table>
                </th>
                <th>
                    <table class="flex column">
                        <td>{{'global.yes' | translate}}</td>
                        <td>{{'global.no' | translate}}</td>
                        <td>{{'global.no' | translate}}</td>
                    </table>
                </th>
            </tr>
            <tr>
                <th colspan="1">{{'global.text.qualified' | translate}}</th>
                <th>
                    <table class="flex column">
                        <td>{{'project-qualification.objectives.header.text.main-objectives' | translate}}</td>
                        <td>{{'project-qualification.gains.header.text.main-gains' | translate}}</td>
                        <td>{{'project-qualification.classifications.header.text.classifications' | translate}}</td>
                    </table>
                </th>
                <th>
                    <table class="flex column">
                        <td>{{'global.yes' | translate}} ({{'global.text.at-least-one' | translate | lowercase}})</td>
                        <td>{{'global.yes' | translate}} ({{'global.text.at-least-one' | translate | lowercase}})</td>
                        <td>{{'global.text.gartner'|translate}}</td>
                    </table>
                </th>
            </tr>
            <tr>
                <th colspan="1">{{'global.text.framed' | translate}}</th>
                <th>
                    <table class="flex column">
                        <td>{{'project-qualification.b-b.header.text.b-b' | translate}}</td>
                        <td>{{'project-qualification.mci.header.text.mci' | translate}}</td>
                    </table>
                </th>
                <th>
                    <table class="flex column">
                        <td>{{'global.yes' | translate}} ({{'project-qualification.text.budget' | translate | lowercase}})</td>
                        <td>{{'global.yes' | translate}} ({{'global.text.at-least-one' | translate | lowercase}})</td>
                    </table>
                </th>
            </tr>
            <tr>
                <th colspan="1">{{'global.text.ready' | translate}}</th>
                <th>
                    <table class="flex column">
                        <td>{{'project-qualification.planning.header.text.planning' | translate}}</td>
                        <td>{{'project-qualification.kick-off.header.text.kick-off' | translate}}</td>
                    </table>
                </th>
                <th>
                    <table class="flex column">
                        <td>{{'global.yes' | translate}}</td>
                        <td>{{'global.yes' | translate}}</td>
                    </table>
                </th>
            </tr>
            <!--<tr>-->
                <!--<th colspan="1" class="border-bottom-left-radius">{{'global.text.aborted' | translate}}</th>-->
                <!--<th>-->
                    <!--<table class="flex column">-->
                        <!--<td>x</td>-->
                    <!--</table>-->
                <!--</th>-->
                <!--<th class="border-bottom-right-radius">-->
                    <!--<table class="flex column">-->
                        <!--<td>x</td>-->
                    <!--</table>-->
                <!--</th>-->
            <!--</tr>-->

        </table>
    </div>
    <div class="edw-modal__footer">
        <button class="edw-button edw-button--light edw-button--md" (click)="close()">
            {{'global.close'|translate}}
        </button>
    </div>
</div>
