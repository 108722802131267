import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {WorkflowService} from '../../../../../../providers/services/workflow/workflow.service';
import {ValidationService} from '../../../../../../providers/services/workflow/validation.service';
import {Workflow} from '../../../../../../models/workflow/Workflow';
import {Validation} from '../../../../../../models/workflow/Validation';
import {ProjectService} from '../../../../../../providers/services/project/project.service';
import {ValidationStepResultOverlayComponent} from './validation-step-result-overlay/validation-step-result-overlay.component';
import {ValidationStepService} from '../../../../../../providers/services/workflow/validation-step.service';
import {ValidationStep} from '../../../../../../models/workflow/ValidationStep';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmComponent} from '@edward-software/edw-fwk-angular-lib/confirm';
import {EdwToolsToastService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwOverlayService} from '@edward-software/edw-fwk-angular-lib/overlay';
import {EdwHttpError, EdwValidation, EdwValidationType} from '@edward-software/edw-fwk-angular-lib/models';
import {ValidationType} from '../../../../../../models/workflow/ValidationType';
import {ValidationTypeService} from '../../../../../../providers/services/workflow/validation-type.service';

@Component({
    selector: 'app-validation-project-modal',
    templateUrl: './validation-project-modal.component.html',
    styleUrls: ['./validation-project-modal.component.scss'],
    providers: [DialogService]
})
export class ValidationProjectModalComponent implements OnInit {
    public configStepsScrollBar: PerfectScrollbarConfigInterface = {
        suppressScrollY: true
    };

    public psStepConfig: PerfectScrollbarConfigInterface = {
        suppressScrollX: true
    };

    psConfigSteps: PerfectScrollbarConfigInterface = {
        suppressScrollY: true
    };

    projectId: string;
    validations: Validation[];
    validationCustoms: EdwValidationType[];
    selectedValidation: EdwValidation;
    workflow: Workflow;

    validationStepResults: any;
    validationsLoading = false;

    constructor(public ref: DynamicDialogRef,
                public dialogConfig: DynamicDialogConfig,
                private projectService: ProjectService,
                private workflowService: WorkflowService,
                private validationService: ValidationService,
                private validationTypeService: ValidationTypeService,
                private toastService: EdwToolsToastService,
                private overlayService: EdwOverlayService,
                private validationStepService: ValidationStepService,
                private dialogService: DialogService,
                private translateService: TranslateService) {

    }

    ngOnInit() {
        this.projectId = this.dialogConfig.data.projectId;
        this.workflow = this.dialogConfig.data.workflow;
        this.getProjectValidation();

    }

    /***********************************************************************
     * RECUPERATION des données
     ***********************************************************************/
    /**
     * On récupère les projects Validations
     */

    getProjectValidation() {
        this.validationsLoading = true;
        this.validationTypeService.listFromProject(this.projectId, 'custom')
            .subscribe(
                resp => {
                    this.validationCustoms = resp;
                    if (this.validationCustoms && this.validationCustoms.length > 0) {
                        this.setSelectedValidation(this.validationCustoms[0].validation);
                        this.validationCustoms.forEach(validationCustom => {
                            this.getValidationSteps(validationCustom.validation);
                        });
                    } else {
                        this.validationsLoading = false;
                    }
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                    this.validationsLoading = false;
                }
            );
    }

    getValidationSteps(validation: EdwValidation) {
        this.validationStepService.listFromValidation(validation.id)
            .subscribe(
                resp => {
                    validation.validationSteps = resp;
                    /**
                     * temporaire ici car dans validations Serializer cette fonction ne marche pas
                     *
                     * Apres avoir lancé validation position = 1
                     */
                    this.selectedValidation.currentPosition = 1;
                    /**
                     * On vérifie les steps qui étaient acceptés pour définir la current position de la validation
                     */
                    validation.validationSteps.forEach(step => {
                        if (step.finalResult === 'ACCEPT') {
                            this.selectedValidation.currentPosition += 1;
                        }
                    });
                    this.validationsLoading = false;

                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                    this.validationsLoading = false;
                }
            );
    }


    getContactStepByPriority(priority: number, vStep: ValidationStep) {
        if (vStep.contactValidationStep && vStep.contactValidationStep.length > 0) {
            return vStep.contactValidationStep.find(ctcVStep => ctcVStep.priority === priority);
        } else {
            return null;
        }
    }

    /***********************************************************************
     * TRAITEMENT
     ***********************************************************************/

    /**
     * Setter du selectedValidation
     */
    setSelectedValidation(validation: EdwValidation) {
        this.selectedValidation = validation;

    }

    getValidateSteps(val: Validation, length) {
        const position = val.validationSteps.filter(step => step.validationDate !== null).length + 1;
        if (position >= length) {
            return length;
        }
        return position;
    }


    /***********************************************************************
     * EDITION des données
     ***********************************************************************/
    /**
     * Permet de changer le status d'une validation. ('SUSPENDED, RESUMED, CANCELLED')
     */
    changeStatusValidationStep(validationId: string, event) {
        let danger = '';
        let message = '';
        if (event === 'CANCELLED') {
            danger = 'danger';
            message = this.translateService.instant('homepage.modal.change-statut-cancel.validation');
        } else if (event === 'SUSPENDED') {
            danger = '';
            message = this.translateService.instant('homepage.modal.change-statut-suspend.validation');
        } else if (event === 'RESUMED') {
            danger = '';
            message = this.translateService.instant('homepage.modal.change-statut-resumed.validation');
        }
        const ref = this.dialogService.open(ConfirmComponent, {
            data: {
                message: message,
                type: danger,
                acceptLabel: 'global.confirm',
                rejectLabel: 'global.cancel',
                autofocus: 'reject'
            },
            showHeader: false,
            closeOnEscape: true,
            styleClass: 'edw-modal--small'
        });
        ref.onClose.subscribe((resp: boolean) => {
                if (resp) {
                    this.validationsLoading = true;
                    this.validationService.updateValidationStatus(validationId, event)
                        .subscribe(
                            resp3 => {
                                const projectValidationEdited = this.validationCustoms.find(vc => vc.validation.id === validationId);
                                if (projectValidationEdited instanceof Validation) {
                                    projectValidationEdited.status = event;
                                    this.getProjectValidation();
                                } else {
                                    this.getProjectValidation();
                                }
                                /**
                                 * On avertit que les validations du project ont changé et qu'il faut potentiellement recharger les status
                                 */
                                this.projectService.projectValidationsEdited.next(true);
                                this.toastService.sendSuccess('portfolio.workflow.validation.toast.success.summary.edited', 'portfolio.workflow.validation.toast.success.detail.edited');
                            }, (error: EdwHttpError) => {
                                if (error.code === 400 && error.errors && error.errors[0].system_message === 'validationCannotBeSuspended') {
                                    this.toastService.sendError('workflow-validation.change-status.error.cannot-be-suspended', 'workflow-validation.change-status.error.cannot-be-suspended.message');
                                } else if (error.code === 401 && error.message === 'userNotAuthorized') {
                                    this.toastService.sendError('global-toast.error.user-not-authorized', 'global-toast.error.user-not-authorized.message');
                                } else {
                                    this.toastService.sendEdwHttpError(error);
                                }
                                this.validationsLoading = false;
                            }
                        );
                }
            }
        );
    }


    /***********************************************************************
     * GESTION des modals
     ***********************************************************************/
    close() {
        this.ref.close(this.validationCustoms);
    }


    /***********************************************************************
     * SUPPRESSION des données
     ***********************************************************************/

    /**
     * Supprime la validation
     */
    deleteValidation(val) {
        const ref = this.dialogService.open(ConfirmComponent, {
            data: {
                message: this.translateService.instant('validation-detail-modal.delete-validation-confirm-message'),
                type: 'danger',
                acceptLabel: 'global.confirm',
                rejectLabel: 'global.cancel',
                autofocus: 'reject'
            },
            showHeader: false,
            closeOnEscape: true,
            styleClass: 'edw-modal--small'
        });
        ref.onClose.subscribe((res: boolean) => {
            if (res) {
                this.validationService.delete(val.id)
                    .subscribe(resp => {
                        const validationDeletedIndex = this.validationCustoms.findIndex(vc => vc.validation.id === val.id);
                        if (validationDeletedIndex > -1) {
                            this.validationCustoms.splice(validationDeletedIndex, 1);
                        } else {
                            /*this.getWorkflowValidations();*/
                        }
                        this.toastService.sendSuccess('portfolio.workflow.validation.toast.success.summary.deleted', 'portfolio.workflow.validation.toast.success.detail.deleted');
                    }, error1 => {
                        this.toastService.sendError(error1.message, error1.message);
                    });
            }
        });
    }


    /**
     * Ouverture de l'overlay pour cancel une validation
     */
    showOverlayCancelValidation(el: HTMLDivElement, validation: Validation) {
        const ref = this.overlayService.open(null, {
            attachedTo: el,
            isConfirm: true,
            confirmDanger: true,
            confirmRejectButtonLabel: 'global.cancel',
            confirmAcceptButtonLabel: 'global.confirm',
            confirmTitle: 'portfolio.workflow.validation.confirm-cancel.title',
            confirmMessage: 'portfolio.workflow.validation.confirm-cancel.message'
        });

        ref.onClose.subscribe(resp => {
            if (resp) {
                this.changeStatusValidationStep(validation.id, 'CANCELLED');

            }
        });
    }

    /**
     * Ouverture de l'overlay pour delete une validation
     */
    showOverlayDeleteValidation(el: HTMLDivElement, validation: Validation) {
        const ref = this.overlayService.open(null, {
            attachedTo: el,
            isConfirm: true,
            confirmDanger: true,
            confirmRejectButtonLabel: 'global.cancel',
            confirmAcceptButtonLabel: 'global.confirm',
            confirmTitle: 'portfolio.workflow.validation.confirm-delete.title',
            confirmMessage: 'portfolio.workflow.validation.confirm-delete.message'
        });

        ref.onClose.subscribe(resp => {
            if (resp) {
                this.deleteValidation(validation);
            }
        });
    }

    /**
     * Ouverture d'un overlay pour afficher le commentaire de la validationStep
     */
    showOverlayDetails(validationStep: ValidationStep, overlayContainer: HTMLDivElement) {
        const ref = this.overlayService.open(ValidationStepResultOverlayComponent, {
            attachedTo: overlayContainer,
            isConfirm: false,
            data: {
                validationStep: validationStep
            }
        });

        ref.onClose.subscribe(resp => {

        });
    }
}
