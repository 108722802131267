import {Injectable} from '@angular/core';
import {Objective} from '../../../models/objective/Objective';
import {HttpClient} from '@angular/common/http';
import {ObjectiveSerializer} from '../../../models/objective/ObjectiveSerializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';

@Injectable({
    providedIn: 'root'
})
export class ObjectiveService extends EntityService<Objective> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiProjectUrl,
            'objectives',
            new ObjectiveSerializer()
        );
    }

    /***************
     * GESTION DES OBJECTIVES D'UN PROJECT
     ***************/

    public listFromProject(projectId): Observable<Objective[]> {
        return this.http.get<Objective[]>(`${this.url}/projects/${projectId}/objectives`)
            .pipe(map((data: any) => this.convertData(data)));
    }

    public createFromProject(id, objective: Objective): Observable<Objective> {
        return this.http.post<Objective>(this.url + '/projects/' + id + '/objectives', this.serializer.toApi(objective), this.options)
            .pipe(map(newObjective => {
                return this.serializer.fromJson(newObjective) as Objective;
            }));
    }

    updatePositionList(ids: string[], id) {
        return this.http.put(this.url + '/projects/' + id + '/objectives/position', {objectives: ids}, this.options)
            .pipe(map(gains => gains));
    }
}
