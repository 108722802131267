import {Indicator} from './Indicator';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class IndicatorSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['score', 'score'],
        ['weight', 'weight'],
        ['gartnerItem', 'gartner_item'],
        ['referentialItem', 'referential_item_id'],
    ]);

    fromJson(json: any): Indicator {
        const indicator = new Indicator();
        this.mapping.forEach((value, key, map) => {
            indicator[key] = json[value];
        });

        if (json['referential_item']) {
            indicator.referentialItem = json['referential_item']['id'];
        }
        return indicator;
    }

    toApi(indicator: Indicator): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = indicator[key];
        });

        if (indicator.referentialItem === null) {
            delete obj['referential_item_id'];
        }

        return obj;
    }

}
