import {SubPortfolio} from '../sub-portfolio/SubPortfolio';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class ProjectDenorm extends Entity {

    private _ownerCompanyId: string;
    private _ownerCompanyName: string;
    private _ownerName: string;
    private _ownerFirstName: string;
    private _ownerId: string;
    private _ownerLastName: string;
    private _ownerTrigram: string;
    private _ownerPictureUrl: string;
    private _portfolioId: string;
    private _portfolioName: string;
    private _projectReference: string;
    private _projectId: string;
    private _projectLocked: Date;
    private _projectAborted: Date;
    private _projectName: string;
    private _projectShortName: string;
    private _stakesValue: number;
    private _gartnerValue: number;
    private _budgetValue: number;
    private _accessibilityValue: number;
    private _workspace: string;
    private _status: string;
    private _progressValue: number;
    private _visible: boolean;
    private _pixel: number;
    private _subPortfolios: SubPortfolio[];


    constructor() {
        super();
        this.subPortfolios = [];
    }

    get subPortfolios(): SubPortfolio[] {
        return this._subPortfolios;
    }

    set subPortfolios(value: SubPortfolio[]) {
        this._subPortfolios = value;
    }

    get ownerCompanyId(): string {
        return this._ownerCompanyId;
    }

    set ownerCompanyId(value: string) {
        this._ownerCompanyId = value;
    }

    get ownerCompanyName(): string {
        return this._ownerCompanyName;
    }

    set ownerCompanyName(value: string) {
        this._ownerCompanyName = value;
    }

    get ownerName(): string {
        return this._ownerName;
    }

    set ownerName(value: string) {
        this._ownerName = value;
    }

    get ownerFirstName(): string {
        return this._ownerFirstName;
    }

    set ownerFirstName(value: string) {
        this._ownerFirstName = value;
    }

    get ownerId(): string {
        return this._ownerId;
    }

    set ownerId(value: string) {
        this._ownerId = value;
    }

    get ownerLastName(): string {
        return this._ownerLastName;
    }

    set ownerLastName(value: string) {
        this._ownerLastName = value;
    }

    get ownerTrigram(): string {
        return this._ownerTrigram;
    }

    set ownerTrigram(value: string) {
        this._ownerTrigram = value;
    }

    get ownerPictureUrl(): string {
        return this._ownerPictureUrl;
    }

    set ownerPictureUrl(value: string) {
        this._ownerPictureUrl = value;
    }

    get portfolioId(): string {
        return this._portfolioId;
    }

    set portfolioId(value: string) {
        this._portfolioId = value;
    }

    get portfolioName(): string {
        return this._portfolioName;
    }

    set portfolioName(value: string) {
        this._portfolioName = value;
    }

    get projectId(): string {
        return this._projectId;
    }

    set projectId(value: string) {
        this._projectId = value;
    }

    get projectReference(): string {
        return this._projectReference;
    }

    set projectReference(value: string) {
        this._projectReference = value;
    }

    get projectLocked(): Date {
        return this._projectLocked;
    }

    set projectLocked(value: Date) {
        this._projectLocked = value;
    }

    get projectAborted(): Date {
        return this._projectAborted;
    }

    set projectAborted(value: Date) {
        this._projectAborted = value;
    }

    get projectName(): string {
        return this._projectName;
    }

    set projectName(value: string) {
        this._projectName = value;
    }

    get workspace(): string {
        return this._workspace;
    }

    set workspace(value: string) {
        this._workspace = value;
    }


    get stakesValue(): number {
        return this._stakesValue;
    }

    set stakesValue(value: number) {
        this._stakesValue = value;
    }

    get gartnerValue(): number {
        return this._gartnerValue;
    }

    set gartnerValue(value: number) {
        this._gartnerValue = value;
    }

    get accessibilityValue(): number {
        return this._accessibilityValue;
    }

    set accessibilityValue(value: number) {
        this._accessibilityValue = value;
    }

    get budgetValue(): number {
        return this._budgetValue;
    }

    set budgetValue(value: number) {
        this._budgetValue = value;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    get progressValue(): number {
        return this._progressValue;
    }

    set progressValue(value: number) {
        this._progressValue = value;
    }

    get pixel(): number {
        return this._pixel;
    }

    set pixel(value: number) {
        this._pixel = value;
    }

    get visible(): boolean {
        return this._visible;
    }

    set visible(value: boolean) {
        this._visible = value;
    }
    get projectShortName(): string {
        return this._projectShortName;
    }

    set projectShortName(value: string) {
        this._projectShortName = value;
    }

}
