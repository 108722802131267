import {Workflow} from '../../../models/workflow/Workflow';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {WorkflowSerializer} from '../../../models/workflow/WorkflowSerializer';
import {Injectable} from '@angular/core';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwWorkflow, QueryOption} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})


export class WorkflowService extends EntityService<EdwWorkflow> {


    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl,
            'workflows',
            new WorkflowSerializer()
        );
    }

    deleteStep(stepId: string) {
        return this.http.delete(`${this.url}/steps/${stepId}`);
    }


    /**
     * Prend en param un projet et un workflow
     * Lance le projet dans le process de validation du workflow
     */
    launchProject(workflowId: string, projectId: string, isSubscribe: boolean, launch: boolean, locale: string, modelName) {
        return this.http.post(`${this.url}/${this.resource}/${workflowId}/validations`,
            {
                object_id: projectId,
                contact_sender_subscribe: isSubscribe,
                launch_validation: launch,
                locale: locale,
                object_type: 'PROJECT',
                name: modelName
            },
            this.options
        )
            .pipe(map(
                resp => resp
            ));
    }


    createFromPortfolio(portfolioId: string, workflow: Workflow): Observable<any> {
        return this.http.post(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/portfolios/${portfolioId}/workflows`, this.serializer.toApi(workflow), this.options)
            .pipe(map(resp => this.serializer.fromJson(resp)));
    }



    public listFromPortfolio(portfolioId): Observable<EdwWorkflow[]> {
        return this.http.get<EdwWorkflow[]>(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/portfolios/${portfolioId}/workflows`)
            .pipe(map((data: any) => this.convertData(data.data)
            ));
    }
    public listFromProject(projectId: string, queryOption: QueryOption): Observable<EdwWorkflow[]> {
        // return this.http.get<EdwWorkflow[]>(`${config.apiUrls.apiPpmEndpoint}/projects/${projectId}/workflows?${queryOption.toQueryString()}`)
        return this.http.get<EdwWorkflow[]>(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/projects/${projectId}/workflows`)
            .pipe(map((data: any) => this.convertData(data.data)
            ));
    }
}
