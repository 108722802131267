import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {ContactPortfolios} from './ContactPortfolios';

export class ContactPortfoliosSerializer implements EntitySerializer {

    public mapping = new Map([
        ['contactId', 'contact_id'],
        ['role', 'role'],
    ]);

    fromJson(json: any): ContactPortfolios {
        const contactPortfolios = new ContactPortfolios();
        this.mapping.forEach((value, key, map) => {
            contactPortfolios[key] = json[value];
        });
        return contactPortfolios;
    }

    toApi(contactPortfolios: ContactPortfolios): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = contactPortfolios[key];
        });
        return obj;
    }

}
