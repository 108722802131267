import {Contact} from './Contact';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';


export class ContactSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['civility', 'civility'],
        ['firstName', 'first_name'],
        ['lastName', 'last_name'],
        ['nickname', 'nickname'],
        ['phoneNumber', 'phone_number'],
        ['cellPhoneNumber', 'cell_phone_number'],
        ['jobTitle', 'job_title'],
        ['email', 'email'],
        ['comment', 'comment'],
        ['company', 'company'],
        ['associatedUser', 'associated_user'],
        ['contactFirstName', 'contact_first_name'],
        ['contactLastName', 'contact_last_name'],
        ['contactNickname', 'contact_nickname'],
        ['contactEmail', 'contact_email'],
        ['role', 'role'],
        ['contactId', 'contact_id'],
        ['contactJobTitle', 'contact_job_title'],
        ['companyName', 'company_name'],
        ['contactPhoneNumber', 'contact_phone_number'],
        ['contactPicture', 'contact_picture'],
        ['contactPictureUrl', 'contact_picture_url'],
        ['status', 'status']
    ]);

    fromJson(json: any): Contact {
        const contact = new Contact();
        this.mapping.forEach((value, key, map) => {
            contact[key] = json[value];
        });

        if (json['firstName']) {
            contact.firstName = json['firstName'];
        }
        if (json['lastName']) {
            contact.lastName = json['lastName'];
        }
        return contact;
    }

    toApi(contact: Contact): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = contact[key];
        });
        return obj;
    }

}
