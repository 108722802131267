import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ChangeImpact} from '../../../models/change-impact/ChangeImpact';
import {ChangeImpactSerializer} from '../../../models/change-impact/ChangeImpactSerializer';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';

@Injectable({
    providedIn: 'root'
})
export class ChangeImpactService extends EntityService<ChangeImpact> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiProjectUrl,
            'change_impacts',
            new ChangeImpactSerializer()
        );
    }

    /***************
     * GESTION DES CHANGE IMPACTS D'UN PROJECT
     ***************/

    public listFromProject(projectId): Observable<ChangeImpact[]> {
        return this.http.get<ChangeImpact[]>(`${this.url}/projects/${projectId}/change_impacts`)
            .pipe(map((data: any) => this.convertData(data)));
    }

    public createFromProject(id, changeImpact: ChangeImpact): Observable<ChangeImpact> {
        return this.http.post<ChangeImpact>(this.url + '/projects/' + id + '/change_impacts', this.serializer.toApi(changeImpact), this.options)
            .pipe(map(newChangeImpact => {
                return this.serializer.fromJson(newChangeImpact) as ChangeImpact;
            }));
    }
}
