import {Portfolio} from './Portfolio';
import {ProjectDenorm} from '../project/ProjectDenorm';
import {ProjectDenormSerializer} from '../project/ProjectDenormSerializer';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {ContactPortfolios} from './ContactPortfolios';
import {ContactPortfoliosSerializer} from './ContactPortfoliosSerializer';
import {GovernancePortfolio} from './GovernancePortfolio';
import {GovernancePortfolioSerializer} from './GovernancePortfolioSerializer';

export class PortfolioSerializer implements EntitySerializer {
    private contactPortfoliosSerializer = new ContactPortfoliosSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['description', 'description'],
        ['shortName', 'short_name'],
        ['currency', 'currency'],
        ['type', 'type'],
        ['portfolioCrInfo', 'portfolio_cr_info'],
        ['contactPortfolios', 'contact_portfolios'],
    ]);

    private projectDenormSerializer = new ProjectDenormSerializer();
    private governancePortfolioSerializer = new GovernancePortfolioSerializer();

    fromJson(json: any): Portfolio {
        const portfolio = new Portfolio();
        this.mapping.forEach((value, key, map) => {
            portfolio[key] = json[value];
        });

        /**
         * Création des projects
         */
        const projects = json['projects'];
        if (projects && projects instanceof Array) {
            const projs: ProjectDenorm[] = [];
            projects.forEach(proj => {
                projs.push(this.projectDenormSerializer.fromJson(proj));
            });
            portfolio.projectsDenorm = projs;
        }
        /**
         * Création des governance portfolios
         */
        const governancePortfolios = json['governance_portfolios'];
        if (governancePortfolios && governancePortfolios instanceof Array) {
            const govPortfolios: GovernancePortfolio[] = [];
            governancePortfolios.forEach(gp => {
                govPortfolios.push(this.governancePortfolioSerializer.fromJson(gp));
            });
            portfolio.governancePortfolio = govPortfolios;
        }
        const portfolioIdaList = json['ida_list_portfolios'];
        if (portfolioIdaList && portfolioIdaList instanceof Array && portfolioIdaList.length > 0) {
            portfolio.portfolioIdaListId = portfolioIdaList[0].ida_list_id;
        }
        /**
         * Création des contacts portfolio
         */
        const contactPortfolios = json['contact_portfolios'];
        if (contactPortfolios && contactPortfolios instanceof Array) {
            const ctcPtf: ContactPortfolios[] = [];
            contactPortfolios.forEach(ctc => {
                ctcPtf.push(this.contactPortfoliosSerializer.fromJson(ctc));
            });
            portfolio.contactPortfolios = ctcPtf;
        }
        return portfolio;
    }

    toApi(portfolio: Portfolio): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = portfolio[key];
        });

        /**
         * Récupération des projets
         */
        const projects = portfolio.projectsDenorm;
        if (projects && projects instanceof Array) {
            const projs: ProjectDenorm[] = [];
            projects.forEach(proj => {
                projs.push(this.projectDenormSerializer.toApi(proj));
            });
            obj['projects'] = projs;
        }
        return obj;
    }

}
