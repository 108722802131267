import {ValidationType} from './ValidationType';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {ValidationSerializer} from './ValidationSerializer';

export class ValidationTypeSerializer implements EntitySerializer {
    private validationSerializer = new ValidationSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['data', 'data'],
        ['creationDate', 'date_creation'],
        ['files', 'files'],
        ['attachedFiles', 'attached_files'],
        ['objectId', 'object_id'],
        ['objectType', 'object_type'],
        ['content', 'content'],
        ['zoneId', 'zone_id'],
        ['lockObject', 'lock_object'],
        ['lockedField', 'locked_field']
        // ['projectId', 'project_id'],
        // ['projectExportId', 'project_export_id'],
        // ['projectName', 'project_name'],
        // ['projectShortName', 'project_short_name'],
        // ['projectReference', 'project_reference'],
        // ['projectExportPdfUrl', 'project_export_pdf_url'],

    ]);


    fromJson(json: any): ValidationType {
        const validationCustom = new ValidationType();
        this.mapping.forEach((value, key, map) => {
            validationCustom[key] = json[value];
        });

        /**
         * Création d'une validation
         */
        if (json['validation'] !== null && typeof (json['validation']) !== 'undefined') {
            validationCustom.validation = this.validationSerializer.fromJson(json['validation']);
        }
        return validationCustom;
    }

    toApi(validationCustom: ValidationType): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validationCustom[key];
        });

        return obj;
    }
}
