<div class="cross"
     (click)="close()">
    <i class="edw-icon edw-icon-cross"></i>
</div>

<div class="flex column align-items_flex-start">

    <!--VRAI CODE-->
    <!--STATUT-->
    <!--
        <div class="flex column align-items_flex-start">
            <label class="edw-label">{{'information.workflow.text.status'|translate}}:</label>
            &lt;!&ndash;VALIDATION ACCEPT&ndash;&gt;
            <p *ngIf="validationStep.finalResult === 'ACCEPT'"
               class="font-bold color-accept">
                {{validationStep.finalResult|titlecase}}
            </p>
            &lt;!&ndash;VALIDATION REJECT&ndash;&gt;
            <p *ngIf="validationStep.finalResult === 'REJECT'"
               class="font-bold color-reject">
                {{validationStep.finalResult|titlecase}}
            </p>
            &lt;!&ndash;VALIDATION FORWARD&ndash;&gt;
            <p *ngIf="validationStep.finalResult === 'FORWARD'"
               class="font-bold color-forward">
                {{validationStep.finalResult|titlecase}}
            </p>
            &lt;!&ndash;VALIDATION AUTO-FORWARD&ndash;&gt;
            <p *ngIf="validationStep.finalResult === 'AUTO_FORWARD'"
               class="font-bold color-auto-forward">
                {{validationStep.finalResult|titlecase}}
            </p>
            &lt;!&ndash;VALIDATION EXPIRED&ndash;&gt;
            <p *ngIf="validationStep.finalResult === 'EXPIRED'"
               class="font-bold color-expired">
                {{validationStep.finalResult|titlecase}}
            </p>
            &lt;!&ndash;VALIDATION NULL&ndash;&gt;
            <p *ngIf="validationStep.finalResult === null && !validationStep.finalResult"
               class="font-bold color-in-progress">Validation in progress
            </p>
        </div>
    -->

    <!--Commentaire-->
    <div class="m-t-10 flex column align-items_flex-start">
        <label class="edw-label">{{'workflow-validation.comment'|translate}}:</label>
        <p [innerHTML]="validationStep.validationStepResults[0].comment"></p>
    </div>

    <!--FIN VRAI CODE-->


    <!--
    <div class="container-comment ps left-text" [perfectScrollbar]="psResultScrollBar">
        <div *ngIf="validationStep.validationStepResults[0]">
            <div [ngClass]="{'title-accepted': validationStep.finalResult === 'ACCEPT',
                                                'title-rejected': validationStep.finalResult === 'REJECT',
                                                'title-suspended': validationStep.finalResult === 'SUSPENDED',
                                                'title-in-progress': validationStep.finalResult === null}">
                <h3>{{validationStep.label}}</h3>
            </div>
            <div class="flex m-t-10" *ngIf="validationStep.validationStepResults[0].result">
                <div class="edw-input-group ht-20">
                    <label class="edw-label">{{'information.workflow.text.status'|translate}}:</label>
                    <p *ngIf="validationStep.validationStepResults[0].result === 'REJECT'"
                       class="title-rejected font-bold">
                        {{'workflow-validation.button.rejected'|translate}}
                    </p>
                    <p *ngIf="validationStep.validationStepResults[0].result === 'FORWARD'"
                       class="title-in-progress font-bold">
                        {{'workflow-validation.button.skipped'|translate}}
                    </p>
                    <p *ngIf="validationStep.validationStepResults[0].result === 'ACCEPT'"
                       class="title-accepted font-bold">
                        {{'workflow-validation.button.validated'|translate}}
                    </p>
                </div>
            </div>
            <div class="edw-input-group m-t-10 ht-20"
                 *ngIf="validationStep.contactValidationStep[0]">
                <div class="edw-label">{{'information.workflow.text.responsible'|translate}}:</div>
                <p>{{validationStep.contactValidationStep[0].contact.firstName}}
                    {{validationStep.contactValidationStep[0].contact.lastName}}</p>
            </div>

            <div class="edw-input-group m-t-10 ht-20"
                 *ngIf="validationStep.contactValidationStep[1]">
                <div class="edw-label">{{'information.workflow.text.substitute'|translate}}:</div>
                <p>{{validationStep.contactValidationStep[1].contact.firstName}}
                    {{validationStep.contactValidationStep[1].contact.lastName}}</p>
            </div>
            <div class="edw-input-group edw-input-group&#45;&#45;flex-start m-t-10 ht-20"
                 *ngIf="validationStep.validationStepResults[0].comment">
                <div class="edw-label">{{'information.workflow.text.description'|translate}}:</div>
                <p [innerHTML]="validationStep.validationStepResults[0].comment" class="left-text"></p>
            </div>
        </div>
        <div *ngIf="validationStep.validationStepResults[1]" class="m-t-10">
            <h3 class="primary-color">{{'information.workflow.text.substitute'|translate}}</h3>
            <div class="flex" *ngIf="validationStep.validationStepResults[1].result">
                <p class="edw-label left-text">{{'information.workflow.text.status'|translate}} :&nbsp;</p>
                <p>
                    <ng-container
                        *ngIf="validationStep.validationStepResults[1].result === 'REJECT'">{{'workflow-validation.button.rejected'|translate}}
                    </ng-container>
                    <ng-container
                        *ngIf="validationStep.validationStepResults[1].result === 'FORWARD'">{{'workflow-validation.button.skipped'|translate}}
                    </ng-container>
                    <ng-container
                        *ngIf="validationStep.validationStepResults[1].result === 'ACCEPT'">{{'workflow-validation.button.validated'|translate}}
                    </ng-container>
                </p>
            </div>
            <div class="edw-input-group">
                <div class="flex column" *ngIf="validationStep.validationStepResults[1].comment">
                    <p class="edw-label left-text">{{'information.workflow.text.description'|translate}} :</p>
                    <p [innerHTML]="validationStep.validationStepResults[1].comment" class="left-text"></p>
                </div>
            </div>
        </div>


    </div>

    -->

</div>
