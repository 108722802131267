<div class="new-project-validation">
    <fieldset [disabled]="submitDisabled">
        <div class="edw-modal edw-modal--small" #overlayContainer>
            <div class="edw-modal__header">
                <h2>{{'project.workflow-validation-modal.title'|translate}}</h2>
                <ng-container *ngIf="!submitDisabled">
                    <div class="cross"
                         [ngClass]="{'lightgray-color': submitDisabled}"
                         (click)="close()">
                        <i class="edw-icon edw-icon-cross"></i>
                    </div>
                </ng-container>
            </div>
            <div class="edw-modal__body">
                <ng-container *ngIf="!workflowsLoading">
                    <ng-container *ngIf="workflows && workflows.length > 0">
                        <p-dropdown [(ngModel)]="workflow"
                                    [disabled]="submitDisabled"
                                    [options]="workflows"
                                    styleClass="edw-dropdown edw-dropdown--input m-b-12"
                                    optionLabel="name"
                                    [placeholder]="'information.workflow.select-workflow.placeholder'|translate">
                        </p-dropdown>
                        <input id="modelName"
                               name="modelName"
                               [(ngModel)]="modelName"
                               type="text"
                               placeholder="Nom du workflow"
                               class="edw-input ellipsis m-b-12">
                        <p-dropdown [options]="locales"
                                    [ngModel]="locales"
                                    [disabled]="submitDisabled"
                                    [placeholder]="'information.workflow.select-language.placeholder'|translate"
                                    styleClass="edw-dropdown edw-dropdown--input"
                                    (onChange)="addLocale($event)">
                        </p-dropdown>
                        <div class="flex align-items_center m-t-20"
                             [ngClass]="{'lightgray-color': submitDisabled}">
                            <p-checkbox class="edw-checkbox m-r-10" binary="true" [(ngModel)]="isSubscribe"
                                        [disabled]="submitDisabled"
                                        [label]="'workflow-validation.subscribe-workflow'|translate"></p-checkbox>
                        </div>
                    </ng-container>
                    <div *ngIf="!workflows || workflows.length === 0"
                         class="flex align-items_center center center-text font14 no-workflow-message">
                        {{'project.workflow-validation-modal.no-workflow-message'|translate}}
                    </div>
                </ng-container>
                <edw-loader *ngIf="workflowsLoading"></edw-loader>
            </div>
            <div class="edw-modal__footer">
                <ng-container *ngIf="!workflowsLoading && workflows && workflows.length > 0">
                    <button type="button"
                            class="edw-button edw-button--light edw-button--md" (click)="close()"
                            [edwLoader]="submitDisabled"
                            [hasLoader]="false"
                            [defaultClass]="'light'">
                        {{'global.cancel'|translate}}
                    </button>
                    <button
                        [edwLoader]="submitDisabled"
                        [defaultClass]="'primary'"
                        [ngClass]="{'edw-button--primary': workflow, 'edw-button--inactive-solid': !workflow}"
                        [disabled]="!workflow"
                        class="edw-button edw-button--md edw-button--primary" (click)="save(overlayContainer)">
                        {{'global.button.launch'|translate}}
                    </button>
                </ng-container>
                <ng-container *ngIf="!workflowsLoading && (!workflows || workflows.length === 0)">
                    <button
                        (click)="navigateToPortfolioInformation()"
                        class="edw-button edw-button--md edw-button--primary w50">
                        {{'project.workflow-validation-modal.define-workflow'|translate}}
                    </button>
                </ng-container>
            </div>
        </div>
    </fieldset>
</div>
