/* tslint:disable:max-line-length */
import {Injectable} from '@angular/core';
import {Project} from '../../../models/project/Project';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Classification} from '../../../models/classification/Classification';
import {ProjectSerializer} from '../../../models/project/ProjectSerializer';
import {ClassificationSerializer} from '../../../models/classification/ClassificationSerializer';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {EdwToolsDateService, EntityService} from '@edward-software/edw-fwk-angular-lib/providers';
import {Schedule, ScheduleSerializer, Structure, StructureSerializer} from '@edward-software/edw-fwk-angular-lib/models';


@Injectable({
    providedIn: 'root'
})
export class ProjectService extends EntityService<Project> {

    /**
     * SUBSCRIBERS
     */
    public currentProject: BehaviorSubject<Project> = new BehaviorSubject<Project>(null);
    public projectValidationsEdited: Subject<boolean> = new Subject<boolean>();
    public editedProject: Subject<Project> = new Subject<Project>();
    public newProject: Subject<Project> = new Subject<Project>();

    private scheduleSerializer = new ScheduleSerializer();
    private structureSerializer = new StructureSerializer();

    constructor(private http: HttpClient,
                private edwDate: EdwToolsDateService) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiProjectUrl,
            'projects',
            new ProjectSerializer()
        );
    }

    /***************
     * GESTION DES PROJECTS
     ***************/


    createFromPortfolio(portfolioId: string, project: Project): Observable<Project> {
        return this.http.post(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/portfolios/${portfolioId}/projects`,
            this.serializer.toApi(project), this.options)
            .pipe(map(resp => this.serializer.fromJson(resp) as Project));
    }


    /**
     * Récupère le statut du projet (ainsi que sa progressvalue)
     * Return {
     *     status: 'QUALIFIED',
     *     progress_value: 12,23
     * }
     */
    readStatus(projectId: string, param: string = '') {
        return this.http.get(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/${this.resource}/${projectId}/status?${param}`)
            .pipe(map(
                resp => resp
            ));
    }


    /**
     * Abort un project
     */
    abortProject(projectId: string) {
        return this.http.put(`${this.url}/${this.resource}/${projectId}/abort`, {}, this.options)
            .pipe(map(resp => resp));
    }

    /**
     * Active un projet
     */
    unabort(projectId: string) {
        return this.http.put(`${this.url}/${this.resource}/${projectId}/unabort`, {}, this.options)
            .pipe(map(resp => resp));
    }

    deleteMultiple(projectIds: string[]): Observable<any[]> {
        if (projectIds && projectIds.length > 0) {
            const requests = [];
            projectIds.forEach(id => {
                requests.push(this.http.delete(`${this.url}/${this.resource}/${id}`));
            });

            return forkJoin(requests);
        }
    }


    /***************
     * GESTION DES CLASSIFICATIONS DU PROJECT
     ***************/

    getProjectClassifications(id) {
        return this.http.get<Classification[]>(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/${this.resource}/${id}/classifications`)
            .pipe(map(classifs => {
                const classifications: Classification[] = [];
                classifs['data'].forEach(c => {
                    classifications.push(new ClassificationSerializer().fromJson(c));
                });
                return classifications;
            }));
    }

    /***************
     * GESTION DES PLANNINGS DU PROJET PPM
     ***************/
    createPpmPlanning(projectId: string, type: string, endDate: Date, month_duration: any): Observable<any> {
        return this.http.post(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/${this.resource}/${projectId}/schedules`,
            {
                type: type.toUpperCase(),
                end_date: endDate,
                month_duration: month_duration
            }
        )
            .pipe(map(
                resp => resp
            ));
    }


    /**
     * Recupere le Macro Timeslot d'un projet
     * http://url?margin_before=X&margin_after=Y
     */
    getPpmPlanning(projectId: string): Observable<Schedule[]> {
        return this.http.get(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiScheduleUrl}/${this.resource}/${projectId}/schedules/PPM_MAIN/structure?margin_after=5&margin_before=5`)
            .pipe(map(
                (resp: any) => resp.map(item => this.scheduleSerializer.fromJson(item))
            ));
    }

    /***************
     * GESTION DES PLANNINGS DU PROJET KICKOFF
     ***************/
    createKickOffPlanning(projectId: string, type: string, endDate: Date): Observable<any> {
        return this.http.post(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/${this.resource}/${projectId}/schedules`,
            {
                type: type.toUpperCase(),
                end_date: this.edwDate.normalize(endDate),
            }
        )
            .pipe(map(
                resp => resp
            ));
    }

    /**
     * Recupere le KICKOFF d'un projet
     */
    getKickOffPlanning(projectId: string): Observable<Schedule[]> {
        return this.http.get(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiScheduleUrl}/${this.resource}/${projectId}/schedules/PPM_KICKOFF/structure?margin_after=1&margin_before=1`)
            .pipe(map(
                (resp: any) => resp.map(item => this.scheduleSerializer.fromJson(item))
            ));
    }

    getProjectSchedule(portfolioId: string): Observable<Structure> {
        return this.http.get(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/portfolios/${portfolioId}/projects/schedules/structure`)
            .pipe(map(
                resp => {
                    return this.structureSerializer.fromJson(resp['structure']) as Structure;

                }
            ));
    }

    /**************************
     * Quand on edit project,
     * on l'envoie aux observers
     *************************/

    setEditedProject(project: Project) {
        this.editedProject.next(project);
    }

    /**************************
     * Quand on cree new project,
     * on l'envoie aux observers
     *************************/

    setNewProject(project: Project) {
        this.newProject.next(project);
    }

    /************************
     * PROJECT REVIEW
     **********************/

    getBudgetChart(projectId: string) {
        return this.http.get(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/${this.resource}/${projectId}/analysis/budget`)
            .pipe(map(
                resp => resp
            ));
    }

    getHRChart(projectId: string) {
        return this.http.get(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/${this.resource}/${projectId}/analysis/hr`)
            .pipe(map(
                resp => resp
            ));
    }


    /**
     * Permet d'editer le statut d'un projet au click
     */
    editStatus(projectId: string, status: string) {
        return this.http.put(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/${this.resource}/${projectId}/status`, {
            status: status
        })
            .pipe(map(
                resp => resp
            ));
    }

    /**
     * Export ZIP
     */
    exportZip(projectId: string, locale: string) {
        return this.http.post(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/${this.resource}/${projectId}/zip_files`,
            {
                locale: locale
            }
        )
            .pipe(map(
                resp => resp
            ));
    }

}


