import {Gain} from './Gain';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class GainSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['content', 'content'],
        ['position', 'position']
    ]);

    fromJson(json: any): Gain {
        const gain = new Gain();
        this.mapping.forEach((value, key, map) => {
            gain[key] = json[value];
        });
        return gain;
    }

    toApi(gain: Gain): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = gain[key];
        });
        return obj;
    }

}
