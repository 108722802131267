import {Objective} from './Objective';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ObjectiveSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['content', 'content'],
        ['position', 'position']
    ]);

    fromJson(json: any): Objective {
        const objective = new Objective();
        this.mapping.forEach((value, key, map) => {
            objective[key] = json[value];
        });
        return objective;
    }

    toApi(objective: Objective): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = objective[key];
        });
        return obj;
    }

}
