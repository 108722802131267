import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class ValidationStepResult extends Entity {

    private _comment: string;
    private _result: string;


    constructor() {
        super();
    }

    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
    }

    get result(): string {
        return this._result;
    }

    set result(value: string) {
        this._result = value;
    }
}
