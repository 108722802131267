import { Component, OnInit } from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-rules-status-modal',
  templateUrl: './rules-status-modal.component.html',
  styleUrls: ['./rules-status-modal.component.scss']
})
export class RulesStatusModalComponent implements OnInit {

  constructor( private ref: DynamicDialogRef) { }

  ngOnInit() {
  }

  close() {
    this.ref.close();
  }

}
