<div class="flex align-items_center m-y-6" (click)="toggle()">
    <i class="edw-icon edw-icon--primary"
       [ngClass]="{'edw-icon-mini-plus': !showMore, ' edw-icon-mini-minus': showMore}"></i>
    <p class="font-bold primary-color">
        <ng-container *ngIf="showMore">{{'global.hide'|translate}}</ng-container>
        <ng-container *ngIf="!showMore">{{'global.show-more'|translate}}</ng-container>
    </p>
</div>

<div class="project-thread-navbar" *ngIf="showMore && projectIdCard">
    <ng-container *ngIf="isAuthorized && !portfolioLoading">

        <p-accordion [multiple]="true"
                     (onOpen)="onTabOpen($event.index)">
            <p-accordionTab *ngFor=" let tab of projectTabs">
                <p-header>
                    {{tab.title}}
                </p-header>

                <ng-container [ngSwitch]="tab.label">
                    <!--**** ng-container pour project detail ****-->
                    <ng-container *ngSwitchCase="'projectDetails'">
                        <ng-container *ngIf="projectIdCard.project ">
                            <div class="left-text">
                                <label
                                    class="edw-label edw-label&#45;&#45;lightgrey edw-label&#45;&#45;xs m-b-6">Portfoflio
                                    Name</label>
                                <p>{{portfolio.name}}</p>
                            </div>
                            <div class="flex align-items_center project-detail m-t-12 m-b-6">
                                <div>
                                    <label
                                        class="edw-label edw-label--lightgrey edw-label--xs m-b-3 m-r-24">Reference</label>
                                    <edw-inplace-text [data]="projectIdCard.project.reference"
                                                      [disabled]="true"
                                                      [type]="'text'">
                                    </edw-inplace-text>
                                </div>
                                <div>
                                    <label class="edw-label edw-label--lightgrey edw-label--xs m-b-3">Shortname</label>
                                    <edw-inplace-text [data]="projectIdCard.project.shortName"
                                                      [disabled]="true"
                                                      [type]="'text'">
                                    </edw-inplace-text>
                                </div>
                            </div>
                            <div class="left-text">
                                <label
                                    class="edw-label edw-label&#45;&#45;lightgrey edw-label&#45;&#45;xs m-y-6">Owner</label>
                                <div class="flex align-items_center">
                                    <div class="edw-profil__image edw-profil__image&#45;&#45;xs m-l-0"></div>
                                    <p>{{projectIdCard.owner.firstName}} {{projectIdCard.owner.lastName}}</p>
                                </div>
                            </div>
                            <div class="left-text">
                                <label
                                    class="edw-label edw-label&#45;&#45;lightgrey edw-label&#45;&#45;xs m-t-12 m-b-6">Organisation
                                    unit</label>
                                <div class="flex align-items_center">
                                    <div class="edw-pastil__item edw-pastil__item&#45;&#45;square"
                                         style="background-color: yellow;"></div>
                                    <ng-container *ngIf="projectIdCard.orgUnit && projectIdCard.orgUnit.name">
                                        <p>{{projectIdCard.orgUnit.name}}</p>
                                    </ng-container>

                                </div>
                            </div>
                            <div class="left-text">
                                <label
                                    class="edw-label edw-label&#45;&#45;lightgrey edw-label&#45;&#45;xs m-t-12 m-b-6">Sub
                                    portfolios</label>
                                <ng-container
                                    *ngIf="projectIdCard.subPortfolios && projectIdCard.subPortfolios.length > 0">
                                    <div class="flex align-items_center"
                                         *ngFor="let subPortfolio of projectIdCard.subPortfolios">
                                        <div class="edw-pastil__item"
                                             [ngStyle]="{'background-color': subPortfolio.color}"></div>
                                        <p>{{subPortfolio.name}}</p>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="!projectIdCard.subPortfolios || projectIdCard.subPortfolios.length < 1">
                                    <p>N/A</p>
                                </ng-container>

                            </div>
                        </ng-container>
                    </ng-container>

                    <!--**********************-->

                    <ng-container *ngSwitchCase="'objective'">
                        <ng-container *ngIf="projectIdCard.objectives && projectIdCard.objectives.length >= 1">
                            <div class="objectives">
                                <edw-inplace-list
                                    [data]="projectIdCard.objectives"
                                    *ngIf="projectIdCard.objectives && !tab.loading"
                                    [disabled]="true"
                                    [label]="'content'"
                                    [dataType]="'object'"
                                    [listStyle]="'numbered'">
                                </edw-inplace-list>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!projectIdCard.objectives || projectIdCard.objectives.length < 1">
                            N/A
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'gain'">
                        <ng-container *ngIf="projectIdCard.gains && projectIdCard.gains.length >= 1">
                            <div class="objectives">
                                <edw-inplace-list
                                    [data]="projectIdCard.gains"
                                    *ngIf="projectIdCard.gains && !tab.loading"
                                    [disabled]="true"
                                    [label]="'content'"
                                    [dataType]="'object'"
                                    [listStyle]="'numbered'">
                                </edw-inplace-list>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!projectIdCard.gains || projectIdCard.gains.length < 1">
                            N/A
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'classification'">
                        <ng-container
                            *ngIf="projectIdCard.classifications && projectIdCard.classifications.length > 0 && !tab.loading">
                            <div *ngFor="let classif of projectIdCard.classifications"
                                 class="classification flex align-items_center space-between m-b-3">
                                <p class="font-bold black-color">{{classif.type}}</p>
                                <p class="primary-color font-bold">
                                    <ng-container *ngIf="classif.type === 'GARTNER'">
                                        <span class="classification__result">
                                            {{('gartner.' + classif.averageScore|lowercase)|translate}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="classif.type !== 'GARTNER'">
                                        {{classif.averageScore}}
                                    </ng-container>
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="!projectIdCard.classifications || projectIdCard.classifications.length < 1">
                            N/A
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'resources'">
                        <ng-container *ngIf="projectIdCard.humanResource">
                            <div class="resources">
                                <table *ngIf="projectIdCard.humanResource && !tab.loading" class="w100">
                                    <tr>
                                        <th rowspan="1">{{'project-qualification.text.resources' | translate}}</th>
                                        <th rowspan="1">{{'project-qualification.table.text.man-days' | translate}}</th>
                                        <th rowspan="1">{{'project-qualification.table.text.costs' | translate}} {{this.currencySymbol}}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="1">{{'project-qualification.table.text.internal' | translate}}</th>
                                        <td rowspan="1">
                                            <edw-inplace-text [data]="projectIdCard.humanResource?.intManDays"
                                                              [disabled]="true"
                                                              [type]="'number'"
                                                              styleClass="edw-inplace__label--center">
                                            </edw-inplace-text>
                                        </td>
                                        <td rowspan="1">
                                            <edw-inplace-text [data]="projectIdCard.humanResource?.intCosts"
                                                              [disabled]="true"
                                                              [type]="'number'"
                                                              styleClass="edw-inplace__label--center">
                                            </edw-inplace-text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="1">{{'project-qualification.table.text.external' | translate}}</th>
                                        <td rowspan="1">
                                            <edw-inplace-text [data]="projectIdCard.humanResource?.extManDays"
                                                              [disabled]="true"
                                                              [type]="'number'"
                                                              styleClass="edw-inplace__label--center">
                                            </edw-inplace-text>
                                        </td>
                                        <td rowspan="1">
                                            <edw-inplace-text [data]="projectIdCard.humanResource?.extCosts"
                                                              [disabled]="true"
                                                              [type]="'number'"
                                                              styleClass="edw-inplace__label--center">
                                            </edw-inplace-text>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!projectIdCard.humanResource">
                            N/A
                        </ng-container>

                    </ng-container>

                    <ng-container *ngSwitchCase="'bcase'">
                        <ng-container *ngIf="projectIdCard.bCaseBudget && !tab.loading">
                            <div class="bcase-item flex align-items_center space-between">
                                <p class="font-bold black-color">{{'project-qualification.text.budget' | translate | uppercase}}
                                </p>
                                <edw-inplace-text [data]="projectIdCard.bCaseBudget?.budget || ''"
                                                  [disabled]="true"
                                                  [type]="'number'"
                                                  styleClass="edw-inplace__label--right">
                                </edw-inplace-text>
                                <p>{{this.currencySymbol}}</p>
                            </div>
                            <div class="bcase-item flex align-items_center space-between">
                                <p class="font-bold black-color">{{'project-qualification.text.capex' | translate | uppercase}}
                                </p>
                                <edw-inplace-text [data]="projectIdCard.bCaseBudget?.capex || ''"
                                                  [disabled]="true"
                                                  [type]="'number'"
                                                  styleClass="edw-inplace__label--right">
                                </edw-inplace-text>
                                <p>{{this.currencySymbol}}</p>
                            </div>
                            <div class="bcase-item flex align-items_center space-between">
                                <p class="font-bold black-color">{{'project-qualification.text.opex' | translate | uppercase}}
                                </p>
                                <edw-inplace-text [data]="projectIdCard.bCaseBudget?.opex || ''"
                                                  [disabled]="true"
                                                  [type]="'number'"
                                                  styleClass="edw-inplace__label--right">
                                </edw-inplace-text>
                                <p>{{this.currencySymbol}}</p>
                            </div>
                            <div class="bcase-item flex align-items_center space-between">
                                <p class="font-bold black-color">{{'project-qualification.text.roi' | translate | uppercase}}
                                </p>
                                <edw-inplace-text [data]="projectIdCard.bCaseBudget?.roi || ''"
                                                  [disabled]="true"
                                                  [type]="'number'"
                                                  styleClass="edw-inplace__label--right">
                                </edw-inplace-text>
                                <span>{{'qualification.Months' | translate}}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!projectIdCard.bCaseBudget">
                            N/A
                        </ng-container>

                    </ng-container>

                    <ng-container *ngSwitchCase="'changeImpact'">
                        <ng-container *ngIf="projectIdCard.changeImpacts && projectIdCard.changeImpacts.length > 0">
                            <div class="change-impact m-b-6">
                                <p class="left-text">
                                    1. {{'project-qualification.text.tool-equipement' | translate}} :</p>
                                <edw-inplace-text [data]="getChangeImpactContentByOmoc('Tool/Equipment')"
                                                  [disabled]="true">
                                </edw-inplace-text>
                            </div>
                            <div class="change-impact m-b-6">
                                <p class="left-text">2. {{'project-qualification.text.business' | translate}} :</p>
                                <edw-inplace-text [data]="getChangeImpactContentByOmoc('Business')"
                                                  [disabled]="true">
                                </edw-inplace-text>
                            </div>
                            <div class="change-impact m-b-6">
                                <p class="left-text">3. {{'project-qualification.text.organization' | translate}}
                                    :</p>
                                <edw-inplace-text [data]="getChangeImpactContentByOmoc('Organization')"
                                                  [disabled]="true">
                                </edw-inplace-text>
                            </div>
                            <div class="change-impact">
                                <p class="left-text">4. {{'project-qualification.text.culture' | translate}} :</p>
                                <edw-inplace-text [data]="getChangeImpactContentByOmoc('Culture')"
                                                  [disabled]="true">
                                </edw-inplace-text>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!projectIdCard.changeImpacts || projectIdCard.changeImpacts.length < 1">
                            N/A
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'ppmPlanning'">
                        <ng-container *ngIf="projectIdCard.ppmPlanning">
                            <div class="flex align-items_center space-between m-b-12">
                                <p class="font-bold black-color">{{'global.text.analyse-design'|translate}}</p>
                                {{projectIdCard.ppmPlanning.structure.getActivityByName('ANALYSE_DESIGN').startDate|date:'shortDate'}}
                                >
                                {{projectIdCard.ppmPlanning.structure.getActivityByName('ANALYSE_DESIGN').endDate|date:'shortDate'}}
                            </div>
                            <div class="flex align-items_center space-between m-b-12">
                                <p class="font-bold black-color">{{'global.text.build-develop'|translate}}</p>
                                {{projectIdCard.ppmPlanning.structure.getActivityByName('BUILD_DEVELOP').startDate|date:'shortDate'}}
                                >
                                {{projectIdCard.ppmPlanning.structure.getActivityByName('BUILD_DEVELOP').endDate|date:'shortDate'}}
                            </div>
                            <div class="flex align-items_center space-between m-b-12">
                                <p class="font-bold black-color">{{'global.text.install-deploy'|translate}}</p>
                                {{projectIdCard.ppmPlanning.structure.getActivityByName('INSTALL_DEPLOY').startDate|date:'shortDate'}}
                                >
                                {{projectIdCard.ppmPlanning.structure.getActivityByName('INSTALL_DEPLOY').endDate|date:'shortDate'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!projectIdCard.ppmPlanning">
                            N/A
                        </ng-container>

                    </ng-container>

                    <ng-container *ngSwitchCase="'kickOffPlanning'">
                        <ng-container *ngIf="projectIdCard.kickOffPlanning">
                            <div class="flex align-items_center space-between m-b-12">
                                <p class="font-bold black-color">Mobilize</p>
                                {{projectIdCard.kickOffPlanning.structure.getActivityByName('MOBILIZE').startDate|date:'shortDate'}}
                                >
                                {{projectIdCard.kickOffPlanning.structure.getActivityByName('MOBILIZE').startDate|date:'shortDate'}}
                            </div>
                            <div class="flex align-items_center space-between m-b-12">
                                <p class="font-bold black-color">Inform</p>
                                {{projectIdCard.kickOffPlanning.structure.getActivityByName('INFORM').startDate|date:'shortDate'}}
                                >
                                {{projectIdCard.kickOffPlanning.structure.getActivityByName('INFORM').startDate|date:'shortDate'}}
                            </div>
                            <div class="flex align-items_center space-between m-b-12">
                                <p class="font-bold black-color">Organize</p>
                                {{projectIdCard.kickOffPlanning.structure.getActivityByName('ORGANIZE').startDate|date:'shortDate'}}
                                >
                                {{projectIdCard.kickOffPlanning.structure.getActivityByName('ORGANIZE').startDate|date:'shortDate'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!projectIdCard.kickOffPlanning">
                            N/A
                        </ng-container>
                    </ng-container>
                </ng-container>
            </p-accordionTab>
        </p-accordion>
    </ng-container>
    <div class="danger-color"
         *ngIf="!isAuthorized && !portfolioLoading">
        {{'project.thread-navbar.no-access'|translate}}
    </div>
</div>

