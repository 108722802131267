import {Component, OnInit} from '@angular/core';
import {ProjectIdCard} from '../../../../../models/ProjectIdCard';
import {ContactService} from '../../../../../providers/services/adb/contact.service';
import {OrgUnitService} from '../../../../../providers/services/org-unit/org-unit.service';
import {ProjectService} from '../../../../../providers/services/project/project.service';
import {BcaseBudgetService} from '../../../../../providers/services/bcase-budget/bcase-budget.service';
import {HumanResourceService} from '../../../../../providers/services/human-resource/human-resource.service';
import {ObjectiveService} from '../../../../../providers/services/objective/objective.service';
import {GainService} from '../../../../../providers/services/gain/gain.service';
import {ChangeImpactService} from '../../../../../providers/services/change-impact/change-impact.service';
import {ChangeImpact} from '../../../../../models/change-impact/ChangeImpact';
import {PortfolioService} from '../../../../../providers/services/portfolio/portfolio.service';
import {Portfolio} from '../../../../../models/portfolio/Portfolio';
import {getCurrencySymbol} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {SubPortfolioService} from '../../../../../providers/services/sub-portfolio/sub-portfolio.service';
import {CustomItemComponent} from '@edward-software/edw-fwk-angular-lib/thread-navbar';
import {EdwToolsToastService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwHttpError} from '@edward-software/edw-fwk-angular-lib/models';

@Component({
    selector: 'app-project-thread-navbar-template',
    templateUrl: './project-thread-navbar-template.component.html',
    styleUrls: ['./project-thread-navbar-template.component.scss']
})
export class ProjectThreadNavbarTemplateComponent extends CustomItemComponent implements OnInit {

    projectIdCard: ProjectIdCard = new ProjectIdCard();

    projectTabs: { id: number, label: string, title: string, loading: boolean }[] = [
        {id: 0, label: 'projectDetails', title: `${this.translateService.instant('project-qualification.objectives.header.text.project-details')}`, loading: false},
        {id: 1, label: 'objective', title: `${this.translateService.instant('project-qualification.objectives.header.text.main-objectives')}`, loading: false},
        {id: 2, label: 'gain', title: `${this.translateService.instant('project-qualification.gains.header.text.main-gains')}`, loading: false},
        {id: 3, label: 'classification', title: `${this.translateService.instant('project-qualification.classifications.header.text.classifications')}`, loading: false},
        {id: 4, label: 'resources', title: `${this.translateService.instant('project-qualification.text.resources')}`, loading: false},
        {id: 5, label: 'bcase', title: `${this.translateService.instant('project-qualification.b-b.header.text.b-b')}`, loading: false},
        {id: 6, label: 'changeImpact', title: `${this.translateService.instant('project-qualification.mci.header.text.mci')}`, loading: false},
        {id: 7, label: 'ppmPlanning', title: `${this.translateService.instant('project-qualification.planning.header.text.planning')}`, loading: false},
        {id: 8, label: 'kickOffPlanning', title: `${this.translateService.instant('project-qualification.kick-off.header.text.kick-off')}`, loading: false}
    ];

    /**
     * Récupération du portfolio
     * Sert également de test pour savoir si l'utilisateur accès aux infos SPM
     */
    portfolio: Portfolio;
    portfolioLoading = false;
    currencySymbol: string;

    /**
     * Est-ce que l'utlisateur a accès aux informations SPM
     */
    isAuthorized = false;

    /**
     * Togglers
     */
    showMore = false;


    constructor(private toastService: EdwToolsToastService,
                private objectiveService: ObjectiveService,
                private gainService: GainService,
                private changeImpactService: ChangeImpactService,
                private projectService: ProjectService,
                private portfolioService: PortfolioService,
                private bCaseBudgetService: BcaseBudgetService,
                private humanResourceService: HumanResourceService,
                private orgUnitService: OrgUnitService,
                private subPortfolioService: SubPortfolioService,
                private contactService: ContactService,
                private translateService: TranslateService) {
        super();
    }

    ngOnInit() {
    }

    /*************************************
     * OUVERTURE DES TOGGLES
     ************************************/

    /**
     * Toogle pour afficher plus d'infos sur le projet
     */
    toggle() {
        this.showMore = !this.showMore;
        if (this.showMore) {
            this.getPortfolioFromProject();
        }
    }

    /**
     * Aiguilleur à l'ouverture d'un panel
     */
    onTabOpen(index: number) {
        const selectedTab = this.projectTabs.find(tab => tab.id === index);
        if (selectedTab) {
            switch (selectedTab.label) {
                case 'projectDetails':
                    this.getProjectDetails(selectedTab);
                    break;
                case 'objective':
                    this.getObjectives(selectedTab);
                    break;
                case 'gain':
                    this.getGains(selectedTab);
                    break;
                case 'classification':
                    this.getClassifications(selectedTab);
                    break;
                case 'resources':
                    this.getHumanResource(selectedTab);
                    break;
                case 'bcase':
                    this.getBCaseBudget(selectedTab);
                    break;
                case 'changeImpact':
                    this.getChangeImpacts(selectedTab);
                    break;
                case 'ppmPlanning':
                    this.getPpmPlanning(selectedTab);
                    break;
                case 'kickOffPlanning':
                    this.getKickOffPlanning(selectedTab);
                    break;
            }
        }
    }

    /*************************************
     * RECUPERATION DES DONNEES
     ************************************/

    /**
     * Récupération du portfolio du Project
     */
    getPortfolioFromProject() {
        this.portfolioLoading = true;
        this.portfolioService.readFromProject(this.id)
            .subscribe(
                resp => {
                    this.portfolioLoading = false;
                    this.portfolio = resp;
                    this.currencySymbol = getCurrencySymbol(this.portfolio.currency, 'narrow');
                    this.isAuthorized = true;
                    this.getOwner();
                    this.getOrgUnit();
                    this.getSubPortfolios();
                    this.currencySymbol = getCurrencySymbol(this.portfolio.currency, 'narrow');
                },
                (error: EdwHttpError) => {
                    this.portfolioLoading = false;
                    if (error.code === 401 && error.message === 'userNotAuthorized') {
                        this.isAuthorized = false;
                    } else if (error.code === 404 && error.message === 'projectPortfolioNotFound') {
                        this.isAuthorized = false;
                        this.toastService.sendError('global-toast.error.user-not-authorized', 'project.thread-navbar.no-access');
                    } else {
                        this.toastService.sendEdwHttpError(error);
                    }
                }
            );
    }

    /**
     * Récupération des détails du projets
     */
    getProjectDetails(tab) {
        tab.loading = true;
        this.projectService.read(this.id)
            .subscribe(resp => {
                    this.projectIdCard.project = resp;
                    tab.loading = false;
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                    tab.loading = false;
                });
    }

    /**
     * Récupération du owner du projet
     */
    getOwner() {
        this.contactService.readPPMOwner(this.id)
            .subscribe(
                owner => {
                    this.projectIdCard.owner = owner;
                },
                (error: EdwHttpError) => {
                    if (error.code === 404 && error.message === 'projectOwnerNotFound') {
                    } else {
                        this.toastService.sendEdwHttpError(error);
                    }
                }
            );

    }

    /**
     * Récupération de l'org Unit du projet
     */
    getOrgUnit() {
        this.orgUnitService.readFromProject(this.id)
            .subscribe(
                orgUnits => {
                    this.projectIdCard.orgUnit = orgUnits.find(org => org.portfolioId === this.portfolio.id);
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                }
            );
    }

    /**
     * Récupération des sub-portfolios du projet
     */
    getSubPortfolios() {
        this.subPortfolioService.listFromProject(this.id)
            .subscribe(
                resp => {
                    this.projectIdCard.subPortfolios = resp.data;
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                }
            );
    }

    /**
     * Récupération des classifications du Project
     */
    getClassifications(tab) {
        tab.loading = true;
        this.projectService.getProjectClassifications(this.id)
            .subscribe(
                classifs => {
                    this.projectIdCard.classifications = classifs;
                    tab.loading = false;
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                    tab.loading = false;

                }
            );
    }


    /**
     * Récupération du bCaseBudget du project
     */
    getBCaseBudget(tab) {
        tab.loading = true;
        this.bCaseBudgetService.readFromProject(this.id)
            .subscribe(
                bCaseBudget => {
                    this.projectIdCard.bCaseBudget = bCaseBudget;
                    tab.loading = false;
                },
                (error: EdwHttpError) => {
                    if (error.code === 404 && error.message !== 'bcaseBudgetNotFound') {
                        this.toastService.sendEdwHttpError(error);
                    }
                    tab.loading = false;
                }
            );
    }

    /**
     * Récupération des infos Human Resource detail du project
     */
    getHumanResource(tab) {
        tab.loading = true;
        this.humanResourceService.readFromProject(this.id)
            .subscribe(
                humanResource => {
                    this.projectIdCard.humanResource = humanResource;
                    tab.loading = false;
                },
                (error: EdwHttpError) => {
                    if (error.code === 404 && error.message !== 'hRNotFound') {
                        this.toastService.sendEdwHttpError(error);
                    }
                    tab.loading = false;
                }
            );
    }

    /**
     * Récupère les objectives du projet observé
     */
    getObjectives(tab) {
        tab.loading = true;
        this.objectiveService.listFromProject(this.id)
            .subscribe(
                resp => {
                    this.projectIdCard.objectives = resp;
                    tab.loading = false;
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                    tab.loading = false;
                }
            );
    }

    /**
     * Récupère les gains du projet observé
     */
    getGains(tab) {
        tab.loading = true;
        this.gainService.listFromProject(this.id)
            .subscribe(
                resp => {
                    this.projectIdCard.gains = resp;
                    tab.loading = false;
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                    tab.loading = false;
                }
            );
    }

    /**
     * Récupère les changeImpacts du projet observé
     */
    getChangeImpacts(tab) {
        tab.loading = true;
        this.changeImpactService.listFromProject(this.id)
            .subscribe(
                resp => {
                    this.projectIdCard.changeImpacts = resp;
                    tab.loading = false;
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                    tab.loading = false;
                }
            );
    }

    /**
     * Récupère le content d'un changeImpact en fonction du OMOC demandé
     * Utilisé pour alimenter le champ "data" des inplace-text
     */
    getChangeImpactContentByOmoc(omoc: string): string {
        const changeImpact = this.projectIdCard.changeImpacts.find(ci => ci.type.toLowerCase() === omoc.toLowerCase());
        return (changeImpact && typeof changeImpact !== 'undefined' && changeImpact instanceof ChangeImpact) ? changeImpact.content : '';
    }

    /**
     * Récupère le shcedule PPM du projet observé
     */
    getPpmPlanning(tab) {
        tab.loading = true;
        this.projectService.getPpmPlanning(this.id)
            .subscribe(
                resp => {
                    if (resp && resp.length) {
                        this.projectIdCard.ppmPlanning = resp[0];
                    }
                    tab.loading = false;
                },
                (error: EdwHttpError) => {
                    if (error.code === 404 && error.message === 'scheduleNotFound') {

                    } else {
                        this.toastService.sendEdwHttpError(error);
                    }
                    tab.loading = false;
                }
            );
    }

    /**
     * Récupère les schedule de KICK OFF du projet observé
     */
    getKickOffPlanning(tab) {
        tab.loading = true;
        this.projectService.getKickOffPlanning(this.id)
            .subscribe(
                resp => {
                    if (resp && resp.length) {
                        this.projectIdCard.kickOffPlanning = resp[0];
                    }
                    tab.loading = false;

                },
                (error: EdwHttpError) => {
                    if (error.code === 404 && error.message === 'scheduleNotFound') {

                    } else {
                        this.toastService.sendEdwHttpError(error);
                    }
                    tab.loading = false;
                }
            );
    }
}
