import {HumanResourceItem} from './HumanResourceItem';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class HumanResourceItemSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['type', 'type'],
        ['steerPlanValue', 'steer_plan_value'],
        ['managePlanValue', 'manage_plan_value'],
        ['performPlanValue', 'perform_plan_value'],
        ['steerBuildValue', 'steer_build_value'],
        ['manageBuildValue', 'manage_build_value'],
        ['performBuildValue', 'perform_build_value'],
        ['steerDeployValue', 'steer_deploy_value'],
        ['manageDeployValue', 'manage_deploy_value'],
        ['performDeployValue', 'perform_deploy_value'],
    ]);

    fromJson(json: any): HumanResourceItem {
        const humanResourceItem = new HumanResourceItem();
        this.mapping.forEach((value, key, map) => {
            humanResourceItem[key] = json[value];
        });


        return humanResourceItem;
    }

    toApi(humanResourceItem: HumanResourceItem): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = humanResourceItem[key];
        });
        return obj;
    }
}
