import {SubPortfolio} from './SubPortfolio';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class SubPortfolioSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['color', 'color'],
        ['position', 'position'],
        ['portfolioId', 'portfolio_id']
    ]);


    fromJson(json: any): SubPortfolio {
        const subPortfolio = new SubPortfolio();
        this.mapping.forEach((value, key, map) => {
            subPortfolio[key] = json[value];
        });
        return subPortfolio;
    }

    toApi(subPortfolio: SubPortfolio): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = subPortfolio[key];
        });
        return obj;
    }

}
