import {ProjectDenorm} from '../project/ProjectDenorm';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class SubPortfolio extends Entity {
    private _name?: string;
    private _color?: string;
    private _projectsDenorm?: ProjectDenorm[];
    private _portfolioId?: string;
    private _position?: string;

    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get color(): string {
        return this._color;
    }

    set color(value: string) {
        this._color = value;
    }

    get projectsDenorm(): ProjectDenorm[] {
        return this._projectsDenorm;
    }

    set projectsDenorm(value: ProjectDenorm[]) {
        this._projectsDenorm = value;
    }

    get portfolioId(): string {
        return this._portfolioId;
    }

    set portfolioId(value: string) {
        this._portfolioId = value;
    }

    get position(): string {
        return this._position;
    }

    set position(value: string) {
        this._position = value;
    }
}
