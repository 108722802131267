import {Injectable} from '@angular/core';
import {Gain} from '../../../models/gain/Gain';
import {HttpClient} from '@angular/common/http';
import {GainSerializer} from '../../../models/gain/GainSerializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';

@Injectable({
    providedIn: 'root'
})
export class GainService extends EntityService<Gain> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiProjectUrl,
            'gains',
            new GainSerializer()
        );
    }

    /***************
     * GESTION DES GAINS D'UN PROJECT
     ***************/

    public listFromProject(projectId): Observable<Gain[]> {
        return this.http.get<Gain[]>(`${this.url}/projects/${projectId}/gains`)
            .pipe(map((data: any) => this.convertData(data)
            ));
    }

    public createFromProject(id, gain: Gain): Observable<Gain> {
        return this.http.post<Gain>(this.url + '/projects/' + id + '/gains', this.serializer.toApi(gain), this.options)
            .pipe(map(newGain => {
                return this.serializer.fromJson(newGain) as Gain;
            }));
    }

    updatePositionList(ids: string[], id) {
        return this.http.put(this.url + '/projects/' + id + '/gains/position', {gains: ids}, this.options)
            .pipe(map(gains => gains));
    }
}
