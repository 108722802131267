import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class Objective extends Entity {
    private _content?: string;
    private _position?: number;

    constructor() {
        super();

    }

    get content(): string {
        return this._content;
    }

    set content(value: string) {
        this._content = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    toString() {
        return this._content;
    }
}
