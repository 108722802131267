import {SubPortfolio} from '../sub-portfolio/SubPortfolio';
import {Referential} from '../referential/Referential';
import {ProjectDenorm} from '../project/ProjectDenorm';
import {ChangeRequestDenorm} from '../change-request/ChangeRequestDenorm';
import {OrgUnit} from '../org-unit/OrgUnit';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';
import {ContactPortfolios} from './ContactPortfolios';
import {GovernancePortfolio} from './GovernancePortfolio';
export class Portfolio extends Entity {

    private _name?: string;
    private _description?: string;
    private _shortName?: string;
    private _projectsDenorm?: ProjectDenorm[];
    private _changeRequestsDenorm?: ChangeRequestDenorm[];
    private _subPortfolios: SubPortfolio[];
    private _orgUnits: OrgUnit[];
    private _referentials?: Referential[];
    private _visible?: boolean;
    private _currency?: string;
    private _type?: string;
    private _portfolioCrInfo?: any;
    private _accessPortfolio?: string[];
    private _accessMeeting?: string[];
    private _accessIdaList?: string[];
    private _accessCommittee?: string[];
    private _contactPortfolios?: ContactPortfolios[];
    private _governancePortfolio?: GovernancePortfolio[];
    private _portfolioIdaListId: string;


    constructor() {
        super();
        this.orgUnits = [];
        this.subPortfolios = [];
        this.referentials = [];
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get shortName(): string {
        return this._shortName;
    }

    set shortName(value: string) {
        this._shortName = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get projectsDenorm(): ProjectDenorm[] {
        return this._projectsDenorm;
    }

    set projectsDenorm(value: ProjectDenorm[]) {
        this._projectsDenorm = value;
    }

    get changeRequestsDenorm(): ChangeRequestDenorm[] {
        return this._changeRequestsDenorm;
    }

    set changeRequestsDenorm(value: ChangeRequestDenorm[]) {
        this._changeRequestsDenorm = value;
    }

    get subPortfolios(): SubPortfolio[] {
        return this._subPortfolios;
    }

    set subPortfolios(value: SubPortfolio[]) {
        this._subPortfolios = value;
    }

    get orgUnits(): OrgUnit[] {
        return this._orgUnits;
    }

    set orgUnits(value: OrgUnit[]) {
        this._orgUnits = value;
    }

    get referentials(): Referential[] {
        return this._referentials;
    }

    set referentials(value: Referential[]) {
        this._referentials = value;
    }

    get visible(): boolean {
        return this._visible;
    }

    set visible(value: boolean) {
        this._visible = value;
    }

    get currency(): string {
        return this._currency;
    }

    set currency(value: string) {
        this._currency = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get portfolioCrInfo(): any {
        return this._portfolioCrInfo;
    }

    set portfolioCrInfo(value: any) {
        this._portfolioCrInfo = value;
    }

    get accessPortfolio(): string[] {
        return this._accessPortfolio;
    }

    set accessPortfolio(value: string[]) {
        this._accessPortfolio = value;
    }

    get accessMeeting(): string[] {
        return this._accessMeeting;
    }

    set accessMeeting(value: string[]) {
        this._accessMeeting = value;
    }

    get accessIdaList(): string[] {
        return this._accessIdaList;
    }

    set accessIdaList(value: string[]) {
        this._accessIdaList = value;
    }
    get accessCommittee(): string[] {
        return this._accessCommittee;
    }

    set accessCommittee(value: string[]) {
        this._accessCommittee = value;
    }
    get contactPortfolios(): ContactPortfolios[] {
        return this._contactPortfolios;
    }

    set contactPortfolios(value: ContactPortfolios[]) {
        this._contactPortfolios = value;
    }
    get governancePortfolio(): GovernancePortfolio[] {
        return this._governancePortfolio;
    }

    set governancePortfolio(value: GovernancePortfolio[]) {
        this._governancePortfolio = value;
    }

    get portfolioIdaListId(): string {
        return this._portfolioIdaListId;
    }

    set portfolioIdaListId(value: string) {
        this._portfolioIdaListId = value;
    }

}
