import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HumanResource} from '../../../models/human-resource/HumanResource';
import {HumanResourceSerializer} from '../../../models/human-resource/HumanResourceSerializer';
import {Gain} from '../../../models/gain/Gain';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';

@Injectable({
    providedIn: 'root'
})
export class HumanResourceService extends EntityService<HumanResource> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl,
            'hrs',
            new HumanResourceSerializer()
        );
    }

    public createFromProject(projectId: string, humanResource: HumanResource): Observable<HumanResource> {
        return this.http.post<Gain>(`${this.url}/projects/${projectId}/${this.resource}`, this.serializer.toApi(humanResource), this.options)
            .pipe(map(newHumanResource => {
                return this.serializer.fromJson(newHumanResource) as HumanResource;
            }));
    }

    public readFromProject(projectId): Observable<HumanResource> {
        return this.http.get<HumanResource>(this.url + '/projects/' + projectId + '/' + this.resource)
            .pipe(map(humanResource => {
                return this.serializer.fromJson(humanResource) as HumanResource;
            }));
    }
}
