import {Project} from './project/Project';
import {Gain} from './gain/Gain';
import {Objective} from './objective/Objective';
import {ChangeImpact} from './change-impact/ChangeImpact';
import {Contact} from './contact/Contact';
import {Classification} from './classification/Classification';
import {HumanResource} from './human-resource/HumanResource';
import {BCaseBudget} from './bcase-budget/BCaseBudget';
import {OrgUnit} from './org-unit/OrgUnit';
import {ProjectReview} from './project-review/ProjectReview';
import {SubPortfolio} from './sub-portfolio/SubPortfolio';
import {Schedule} from '@edward-software/edw-fwk-angular-lib/models';

export class ProjectIdCard {
    project: Project;
    gains?: Gain[];
    objectives: Objective[];
    changeImpacts: ChangeImpact[];
    owner: Contact;
    orgUnit: OrgUnit;
    classifications: Classification[];
    humanResource: HumanResource;
    bCaseBudget: BCaseBudget;
    schedule: Schedule;
    ppmPlanning: Schedule;
    kickOffPlanning: Schedule;
    subPortfolios: SubPortfolio[];
    projectReview: ProjectReview;

    constructor() {
    }

    /**
     * Récupère la classsification Gartner parmi les classficications du ProjectIdCard
     * Return null si non trouvée
     */
    getGartner() {
        let gartner: Classification = null;
        if (this.classifications !== null && this.classifications instanceof Array) {
            this.classifications.forEach(classification => {
                if (classification.type === 'GARTNER') {
                    gartner = classification;
                }
            });
        }
        return gartner;
    }


    /**
     * Récupère la classification liée à un réferential donné
     * Return null si non trouvée
     */
    getClassificationByReferential(referentialId: string) {
        let classif: Classification = null;
        if (this.classifications !== null && this.classifications instanceof Array) {
            this.classifications.forEach(classification => {
                if (classification != null && classification.referential === referentialId) {
                    classif = classification;
                }
            });
        }
        return classif;
    }
}
