import {Component, OnInit} from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {ValidationStep} from '../../../../../../../models/workflow/ValidationStep';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {OverlayConfig, OverlayRef} from '@edward-software/edw-fwk-angular-lib/overlay';

@Component({
    selector: 'app-validation-step-result-overlay',
    templateUrl: './validation-step-result-overlay.component.html',
    styleUrls: ['./validation-step-result-overlay.component.scss'],
    providers: [DialogService]
})
export class ValidationStepResultOverlayComponent implements OnInit {
    public psResultScrollBar: PerfectScrollbarConfigInterface = {
        suppressScrollX: true
    };

    validationStep: ValidationStep;
    comment = '';

    constructor(public config: OverlayConfig,
                public ref: OverlayRef) {
    }

    ngOnInit() {
        this.validationStep = this.config.data.validationStep;

    }

    close() {
        this.ref.close();
    }
}
