import {Workflow} from './Workflow';
import {ValidationStep} from './ValidationStep';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class Validation extends Entity {

    private _dateCreation: Date;
    private _contactSenderId: string;
    private _contactSenderSubscribe: boolean;
    private _type: string;
    private _locale: string;
    private _status: string;
    private _workflow: Workflow;
    private _validationSteps: ValidationStep[];
    private _launchDate: Date;
    private _endDate: Date;
    private _custom: any;
    private _currentPosition: number;

    constructor() {
        super();
    }

    get dateCreation(): Date {
        return this._dateCreation;
    }

    set dateCreation(value: Date) {
        this._dateCreation = value;
    }

    get contactSenderId(): string {
        return this._contactSenderId;
    }

    set contactSenderId(value: string) {
        this._contactSenderId = value;
    }

    get contactSenderSubscribe(): boolean {
        return this._contactSenderSubscribe;
    }

    set contactSenderSubscribe(value: boolean) {
        this._contactSenderSubscribe = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get locale(): string {
        return this._locale;
    }

    set locale(value: string) {
        this._locale = value;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    get workflow(): Workflow {
        return this._workflow;
    }

    set workflow(value: Workflow) {
        this._workflow = value;
    }

    get validationSteps(): ValidationStep[] {
        return this._validationSteps;
    }

    set validationSteps(value: ValidationStep[]) {
        this._validationSteps = value;
    }

    get launchDate(): Date {
        return this._launchDate;
    }

    set launchDate(value: Date) {
        this._launchDate = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }
    get currentPosition(): number {
        return this._currentPosition;
    }

    set currentPosition(value: number) {
        this._currentPosition = value;
    }
    get custom(): any {
        return this._custom;
    }

    set custom(value: any) {
        this._custom = value;
    }
}
