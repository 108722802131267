import {Indicator} from '../indicator/Indicator';
import {Classification} from './Classification';
import {IndicatorSerializer} from '../indicator/IndicatorSerializer';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ClassificationSerializer implements EntitySerializer {

    private gartnerItems: string[] = ['TRANSFORMATIONAL', 'OUTCOME_DRIVEN', 'POLICY_DRIVEN', 'CHANGE_ORIENTED', 'BOTTOM_UP'];


    public mapping = new Map([
        ['id', 'id'],
        ['type', 'type'],
        ['averageScore', 'average_score'],
        ['project', 'project_id'],
        ['scoreError', 'score_error']
    ]);

    private indicatorSerializer = new IndicatorSerializer();

    fromJson(json: any): Classification {
        let classification = new Classification();
        this.mapping.forEach((value, key, map) => {
            classification[key] = json[value];
        });

        if (json['referential']) {
            classification.referential = json['referential']['id'];
        }

        /**
         * Création des indicators
         */
        const indicators = json['indicators'];
        if (indicators && indicators instanceof Array) {
            const indics: Indicator[] = [];
            indicators.forEach(ind => {
                indics.push(this.indicatorSerializer.fromJson(ind));
            });
            classification.indicators = indics;
        }
        classification = this.parseItQualification(classification);
        return classification;
    }

    toApi(classification: Classification): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = classification[key];
        });

        if (classification.referential != null) {
            obj['referential_id'] = classification.referential;
        }

        /**
         * Ajout des indicators
         */
        const indicators = classification.indicators;
        if (indicators && indicators instanceof Array) {
            const indics: Indicator[] = [];
            indicators.forEach(ind => {
                indics.push(this.indicatorSerializer.toApi(ind));
            });
            obj['indicators'] = indics;
        }

        /**
         * Si il y a un IT Qualification, c'est que la classfiicatin est un gartner donc on formatte le averageScore
         * Dans le else on rajoute la condition referential != null car si on n'a pas précisé IT Qualification en créant le Gartner
         * On ne veut pas que le averageScore d'un gartner soit transformé en Number
         */
        if ((classification.itQualification && classification.itQualification.length > 0) || this.gartnerItems.includes(classification.averageScore)) {
            obj['average_score'] = classification.averageScore;
            if (classification.itQualification) {
                obj['average_score'] += '-' + classification.itQualification;
            }
        } else if (classification.referential !== null) {
            obj['average_score'] = Number(obj['average_score']);
        }
        return obj;
    }

    /**
     * Permet de parser "averageScore" pour une classification de type GARTNER
     * Le BE nous renvoie concaténer "{gartnerAverageScore}-{itQualification}
     */
    parseItQualification(classification: Classification) {
        if (classification.averageScore && typeof classification.averageScore === 'string') {
            const splitString = (classification.averageScore + '').split('-');
            classification.averageScore = splitString[0];
            classification.itQualification = splitString[1];
        }
        return classification;
    }

}
