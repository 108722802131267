import {OrgUnit} from './OrgUnit';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class OrgUnitSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['color', 'color'],
        ['portfolioId', 'portfolio_id']
    ]);

    fromJson(json: any): OrgUnit {
        const orgUnit = new OrgUnit();
        this.mapping.forEach((value, key, map) => {
            orgUnit[key] = json[value];
        });
        return orgUnit;
    }

    toApi(orgUnit: OrgUnit): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = orgUnit[key];
        });
        return obj;
    }
}
