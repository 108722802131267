import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrgUnit} from '../../../models/org-unit/OrgUnit';
import {OrgUnitSerializer} from '../../../models/org-unit/OrgUnitSerializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';

@Injectable({
    providedIn: 'root'
})
export class OrgUnitService extends EntityService<OrgUnit> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl,
            'org_units',
            new OrgUnitSerializer()
        );
    }


    readFromProject(projectId: string): Observable<OrgUnit[]> {
        return this.http.get<OrgUnit[]>(`${this.url}/projects/${projectId}/${this.resource}`)
            .pipe(map(
                resp => this.convertData(resp)
            ));
    }

    editFromProject(projectId: string, orgUnit: OrgUnit): Observable<OrgUnit> {
        return this.http.put<OrgUnit>(`${this.url}/projects/${projectId}/${this.resource}/${orgUnit.id}`, this.serializer.toApi(orgUnit), this.options)
            .pipe(map(
                resp => this.serializer.fromJson(resp) as OrgUnit
            ));
    }


    listFromPortfolio(portfolioId: string): Observable<OrgUnit[]> {
        return this.http.get<OrgUnit[]>(`${this.url}/portfolios/${portfolioId}/${this.resource}`)
            .pipe(map(
                resp => this.convertData(resp)
            ));
    }

    createFromPortfolio(portfolioId: string, orgUnit: OrgUnit): Observable<OrgUnit> {
        return this.http.post<OrgUnit>(`${this.url}/portfolios/${portfolioId}/${this.resource}`, this.serializer.toApi(orgUnit), this.options)
            .pipe(map(
                resp => this.serializer.fromJson(resp) as OrgUnit
            ));
    }

    public updatePositionList(ids: string[], portfolioId: string) {
        return this.http.put(`${this.url}/portfolios/${portfolioId}/${this.resource}/position`, {org_units: ids}, this.options)
            .pipe(map(orgUnits => orgUnits));
    }

    editFromChangeRequest(changeRequestId: string, orgUnit: OrgUnit) {
        return this.http.put<OrgUnit>(`${this.url}/change_requests/${changeRequestId}/${this.resource}/${orgUnit.id}`, this.serializer.toApi(orgUnit), this.options)
            .pipe(map(
                resp => this.serializer.fromJson(resp) as OrgUnit
            ));
    }
}
