import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class BCaseBudget extends Entity {

    private _budget: string;
    private _capex: string;
    private _opex: string;
    private _roi: string;


    constructor() {
        super();
    }

    get budget(): string {
        return this._budget;
    }

    set budget(value: string) {
        this._budget = value;
    }

    get capex(): string {
        return this._capex;
    }

    set capex(value: string) {
        this._capex = value;
    }

    get opex(): string {
        return this._opex;
    }

    set opex(value: string) {
        this._opex = value;
    }

    get roi(): string {
        return this._roi;
    }

    set roi(value: string) {
        this._roi = value;
    }
}
