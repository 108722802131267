import {ValidationStep} from './ValidationStep';
import {ContactValidationStep} from './ContactValidationStep';
import {ContactValidationStepSerializer} from './ContactValidationStepSerializer';
import {ValidationStepResult} from './ValidationStepResult';
import {ValidationStepResultSerializer} from './ValidationStepResultSerializer';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ValidationStepSerializer implements EntitySerializer {

    private contactValidationStepSerializer = new ContactValidationStepSerializer();
    private validationStepResultSerializer = new ValidationStepResultSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['finalResult', 'final_result'],
        ['validationDate', 'validation_date'],
        ['label', 'label'],
        ['position', 'position'],
        ['stepDuration', 'step_duration'],
        ['description', 'description']

    ]);


    fromJson(json: any): ValidationStep {
        const validationStep = new ValidationStep();
        this.mapping.forEach((value, key, map) => {
            validationStep[key] = json[value];
        });

        /**
         * Création des contactValidationSteps
         */
        const contactValidationStep = json['contact_validation_steps'];
        if (contactValidationStep && contactValidationStep instanceof Array) {
            const cVSteps: ContactValidationStep[] = [];
            contactValidationStep.forEach(cVStep => {
                cVSteps.push(this.contactValidationStepSerializer.fromJson(cVStep));
            });
            validationStep.contactValidationStep = cVSteps;
        }

        /**
         * Création des validationStepResult
         */
        const validationStepResults = json['validation_step_results'];
        if (validationStepResults && validationStepResults instanceof Array) {
            const vStepResult: ValidationStepResult[] = [];
            validationStepResults.forEach(vStep => {
                vStepResult.push(this.validationStepResultSerializer.fromJson(vStep));
            });
            validationStep.validationStepResults = vStepResult;
        }


        return validationStep;
    }

    toApi(validationStep: ValidationStep): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validationStep[key];
        });

        return obj;
    }
}
