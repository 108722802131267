<div class="modal-validations-project" #validationsContainer>
    <div class="edw-modal edw-modal--large">
        <div class="edw-modal__header">
            <h2>{{'view-project-validation.header.text.view-project-validation' | translate}}</h2>
            <div class="cross" (click)="close()">
                <i class="edw-icon edw-icon-cross"></i>
            </div>
        </div>

        <div class="edw-modal__body flex column">
            <ng-container *ngIf="validationCustoms && validationCustoms.length > 0">
                <ng-container *ngIf="!validationsLoading">
                    <ng-container *ngIf="selectedValidation">
                        <div class="workflow__steps flex p-col-12 workflow__background p-0 ps"
                             [perfectScrollbar]="psConfigSteps">
                            <!--                        <div class="workflow-overlay ps"-->
                            <!--                             *ngIf="selectedValidation && selectedValidation.status === 'REJECTED'-->
                            <!--                     || selectedValidation && selectedValidation.status === 'SUSPENDED'-->
                            <!--                     || selectedValidation && selectedValidation.status === 'CANCELLED'-->
                            <!--                     || selectedValidation && selectedValidation.status === 'COMPLETED'">-->
                            <!--                        </div>-->
                            <div class="p-col-12 flex workflow__background workflow ps"
                                 [perfectScrollbar]="configStepsScrollBar">
                                <div class="p-col-5 workflow__steps"
                                     *ngFor="let selectedValidationStep of selectedValidation.validationSteps">
                                    <div class="edw-widget p-10 ps"
                                         [ngClass]="{'border-accept': selectedValidationStep.finalResult === 'ACCEPT',
                                            'border-reject': selectedValidationStep.finalResult === 'REJECT',
                                            'border-forward': selectedValidationStep.finalResult === 'FORWARD',
                                            'border-auto-forward': selectedValidationStep.finalResult === 'AUTO_FORWARD',
                                            'border-expired': selectedValidationStep.finalResult === 'EXPIRED',
                                            'border-in-progress': selectedValidationStep.finalResult === null,
                                            '': selectedValidationStep.finalResult === null}"
                                         [perfectScrollbar]="psStepConfig"
                                         #overlayContainer>
                                        <div
                                            *ngIf="selectedValidationStep.validationStepResults && selectedValidationStep.validationStepResults.length > 0"
                                            class="edw-widget__details cursor-pointer"
                                            (click)="showOverlayDetails(selectedValidationStep, overlayContainer)">
                                            <i class="edw-icon edw-icon-comment"></i>
                                        </div>
                                        <div
                                            class="flex space-between align-items_center workflow__step__title font-bold m-b-10"
                                            [ngClass]="{'color-accept': selectedValidationStep.finalResult === 'ACCEPT',
                                            'color-reject':  selectedValidationStep.finalResult === 'REJECT',
                                            'color-forward': selectedValidationStep.finalResult === 'FORWARD',
                                            'color-auto-forward': selectedValidationStep.finalResult === 'AUTO_FORWARD',
                                            'color-expired': selectedValidationStep.finalResult === 'EXPIRED',
                                            'color-in-progress': selectedValidationStep.finalResult === null}">
                                            {{selectedValidationStep.label}}
                                        </div>

                                        <!--STATUT-->
                                        <div>
                                            <label class="edw-label">{{'information.workflow.text.status'|translate}}
                                                :</label>
                                            <!--VALIDATION ACCEPT-->
                                            <p *ngIf="selectedValidationStep.finalResult === 'ACCEPT'"
                                               class="font-bold color-accept">{{'workflow.validation.status.accept'|translate|titlecase}}
                                            </p>
                                            <!--VALIDATION REJECT-->
                                            <p *ngIf="selectedValidationStep.finalResult === 'REJECT'"
                                               class="font-bold color-reject">{{'workflow.validation.status.reject'|translate|titlecase}}
                                            </p>
                                            <!--VALIDATION FORWARD-->
                                            <p *ngIf="selectedValidationStep.finalResult === 'FORWARD'"
                                               class="font-bold color-forward">{{'workflow.validation.status.forward'|translate|titlecase}}
                                            </p>
                                            <!--VALIDATION AUTO-FORWARD-->
                                            <p *ngIf="selectedValidationStep.finalResult === 'AUTO_FORWARD'"
                                               class="font-bold color-auto-forward">{{'workflow.validation.status.auto-fordward'|translate|titlecase}}
                                            </p>
                                            <!--VALIDATION AUTO-FORWARD-->
                                            <p *ngIf="selectedValidationStep.finalResult === 'EXPIRED'"
                                               class="font-bold color-expired">{{'workflow.validation.status.expire'|translate|titlecase}}
                                            </p>
                                            <!--VALIDATION NULL-->
                                            <!--                                        <p *ngIf="selectedValidationStep.finalResult === null && !selectedValidationStep.finalResult"-->
                                            <!--                                           class="font-bold color-in-progress">{{'workflow.validation.status.in-progress'|translate|titlecase}}-->
                                            <!--                                        </p>-->
                                            <p *ngIf="selectedValidationStep.finalResult === null && !selectedValidationStep.finalResult &&  selectedValidation.currentPosition === (selectedValidationStep.position +1)"
                                               class="font-bold color-in-progress">{{'workflow.validation.status.in-progress'|translate|titlecase}}
                                            </p>
                                        </div>

                                        <!--RESPONSIBLE-->
                                        <div class="m-t-10"
                                             *ngIf="selectedValidationStep.findContactValidationStepByPriority(0)">
                                            <label
                                                class="edw-label">{{'information.workflow.text.responsible'|translate}}
                                                :</label>
                                            <p>{{selectedValidationStep.findContactValidationStepByPriority(0)?.contact.firstName2}} {{selectedValidationStep.findContactValidationStepByPriority(0)?.contact.lastName2}}</p>
                                        </div>

                                        <!--SUBSTITUTE-->
                                        <div class="m-t-10"
                                             *ngIf="selectedValidationStep.findContactValidationStepByPriority(1)">
                                            <label
                                                class="edw-label">{{'information.workflow.text.substitute'|translate}}
                                                :</label>
                                            <p>{{selectedValidationStep.findContactValidationStepByPriority(1)?.contact.firstName2}} {{selectedValidationStep.findContactValidationStepByPriority(1)?.contact.lastName2}}</p>
                                        </div>

                                        <!--DESCRIPTION-->
                                        <div class="m-t-10">
                                            <label
                                                class="edw-label">{{'information.workflow.text.description'|translate}}
                                                :</label>
                                            <p [innerHTML]="selectedValidationStep.description"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>


                    <div class="p-col-12 flex workflow__projects__header">
                        <div class="p-col-4 workflow__projects__header__tilte edw-label">
                            {{'global.text.workflow' | translate}}
                        </div>
                        <div class="p-col-3  workflow__projects__header__tilte edw-label">
                            {{'global.text.date' | translate}}
                        </div>
                        <div class="p-col-2  workflow__projects__header__tilte edw-label">
                            {{'global.text.status' | translate}}
                        </div>
                        <div class="p-col-1  workflow__projects__header__tilte edw-label">
                            {{'global.text.steps' | translate}}
                        </div>
                        <div class="p-col-2  workflow__projects__header__tilte edw-label flex flex-end">
                            {{'global.text.actions' | translate}}
                        </div>
                    </div>

                    <div class="container-projects ps" [perfectScrollbar]="psStepConfig">
                        <div *ngFor="let validationCustom of validationCustoms"
                             (click)="setSelectedValidation(validationCustom.validation)"
                             [ngClass]="{'selectedProject': selectedValidation.id === validationCustom.validation.id}"
                             class="flex align-items_center project-hover cursor-pointer workflow__projects__container">
                            <div class="p-col-4 workflow__projects__header__tilte">
                                {{validationCustom.validation.workflow.name}}
                            </div>
                            <div class="p-col-3  workflow__projects__header__tilte">
                                {{validationCustom.validation.dateCreation|localizedDate: 'short'}}
                            </div>
                            <div class="p-col-2  workflow__projects__header__tilte">
                                <ng-container *ngIf="validationCustom.validation.status === 'CANCELLED'">
                                    <div class="flex align-items_center validation-canceled">
<!--                                        <i class="edw-icon edw-icon-state-cross m-r-5"></i>-->
                                        <p>{{('workflow.validation.status.' + validationCustom.validation.status | lowercase) | translate }}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="validationCustom.validation.status === 'LAUNCHED'">
                                    <div class="flex align-items_center validation-launched">
<!--                                        <i class="edw-icon edw-icon-state-play m-r-5"></i>-->
                                        <p>{{('workflow.validation.status.' + validationCustom.validation.status | lowercase) | translate }}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="validationCustom.validation.status === 'INITIALIZED'">
                                    <div class="flex align-items_center validation-initialized">
<!--                                        <i class="edw-icon edw-icon-state-play m-r-5"></i>-->
                                        <p>{{('workflow.validation.status.' + validationCustom.validation.status | lowercase) | translate }}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="validationCustom.validation.status === 'RESUMED'">
                                    <div class="flex align-items_center validation-resumed">
<!--                                        <i class="edw-icon edw-icon-state-play m-r-5"></i>-->
                                        <p>{{('workflow.validation.status.' + validationCustom.validation.status | lowercase) | translate }}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="validationCustom.validation.status === 'SUSPENDED'">
                                    <div class="flex align-items_center validation-suspended">
<!--                                        <i class="edw-icon edw-icon-state-pause m-r-5"></i>-->
                                        <p>{{('workflow.validation.status.' + validationCustom.validation.status | lowercase) | translate }}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="validationCustom.validation.status === 'VALIDATED'">
                                    <div class="flex align-items_center validation-validated">
<!--                                        <i class="edw-icon edw-icon-state-check m-r-5"></i>-->
                                        <p>{{('workflow.validation.status.' + validationCustom.validation.status | lowercase) | translate }}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="validationCustom.validation.status === 'COMPLETED'">
                                    <div class="flex align-items_center validation-completed">
<!--                                        <i class="edw-icon edw-icon-state-check m-r-5"></i>-->
                                            <p>{{('workflow.validation.status.' + validationCustom.validation.status | lowercase) | translate }}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="validationCustom.validation.status === 'REJECTED'">
                                    <div class="flex align-items_center validation-canceled">
<!--                                        <i class="edw-icon edw-icon-state-cross m-r-5"></i>-->
                                        <p>{{('workflow.validation.status.' + validationCustom.validation.status | lowercase) | translate }}</p>
                                    </div>
                                </ng-container>
                            </div>
                            <div style="width: 60px;">
                                <div class="p-col-1 center-text" *ngIf="validationCustom.validation && validationCustom.validation.validationSteps">
                                 {{getValidateSteps(validationCustom.validation, validationCustom.validation.validationSteps.length)}}/{{validationCustom.validation.validationSteps.length}}
                                </div>
                            </div>
                            <div class="p-col-2  workflow__projects__header__tilte flex flex-end m-0 m-r-5 p-5">
                                <button class="edw-button edw-button--square edw-button--light m-r-5"
                                        *ngIf="validationCustom.validation.status === 'RESUMED' || validationCustom.validation.status === 'INITIALIZED' || validationCustom.validation.status === 'LAUNCHED'"
                                        (click)="changeStatusValidationStep(validationCustom.validation.id, 'SUSPENDED')">
                                    <i class="edw-icon edw-icon--darkgray edw-icon-state-pause"></i>
                                </button>
                                <button class="edw-button edw-button--square edw-button--secondary m-r-5"
                                        *ngIf="validationCustom.validation.status === 'SUSPENDED'"
                                        [disabled]="validationCustom.validation.status === 'CANCELLED'"
                                        [ngClass]="{'edw-button--inactive': validationCustom.validation.status === 'CANCELLED'}"
                                        (click)="changeStatusValidationStep(validationCustom.validation.id, 'RESUMED')">
                                    <i class="edw-icon edw-icon--primary edw-icon-state-play"></i>
                                </button>
                                <button class="edw-button edw-button--square edw-button--warning-secondary"
                                        (click)="changeStatusValidationStep(validationCustom.validation.id, 'CANCELLED')"
                                        *ngIf="validationCustom.validation.status !== 'CANCELLED' && validationCustom.validation.status !== 'REJECTED'">
                                    <i class="edw-icon edw-icon--danger edw-icon-mini-cross"></i>
                                </button>
                                <button *ngIf="validationCustom.validation.status === 'CANCELLED' || validationCustom.validation.status === 'REJECTED'"
                                        class="edw-button edw-button--square edw-button--warning-secondary"
                                        (click)="deleteValidation(validationCustom.validation)">
                                    <i class="edw-icon edw-icon--danger edw-icon-bin"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!validationCustoms || validationCustoms.length < 1">
                <div class="edw-widget edw-widget--lock">
                    <div class="edw-widget__body h100 flex p-justify-center p-align-center">
                        <div class="flex column align-items_center">
                            <p class="black-color font-bold m-b-6">{{'view-validation.modal.card-empty'|translate}}</p>
                            <p>{{'view-validation.modal.card-empty.message'|translate}}</p>
                        </div>

                    </div>
                </div>
            </ng-container>
        </div>

        <edw-loader *ngIf="validationsLoading"></edw-loader>
        <div class="edw-modal__footer">
            <button type="button"
                    class="edw-button edw-button--light edw-button--md" (click)="close()">
                {{'global.close'|translate}}
            </button>
        </div>
    </div>
</div>
