import {ProjectDenorm} from './ProjectDenorm';
import {SubPortfolio} from '../sub-portfolio/SubPortfolio';
import {SubPortfolioSerializer} from '../sub-portfolio/SubPortfolioSerializer';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ProjectDenormSerializer implements EntitySerializer {

    private subPortoflioSerializer = new SubPortfolioSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['ownerCompanyId', 'owner_company_id'],
        ['ownerCompanyName', 'owner_company_name'],
        ['ownerName', 'owner_name'],
        ['ownerFirstName', 'owner_first_name'],
        ['ownerId', 'owner_id'],
        ['ownerLastName', 'owner_last_name'],
        ['ownerTrigram', 'owner_trigram'],
        ['ownerPictureUrl', 'owner_picture_url'],
        ['portfolioId', 'portfolio_id'],
        ['portfolioName', 'portfolio_name'],
        ['gartnerValue', 'gartner_value'],
        ['accessibilityValue', 'accessibility_value'],
        ['stakesValue', 'stakes_value'],
        ['projectId', 'project_id'],
        ['projectLocked', 'project_locked'],
        ['projectAborted', 'project_aborted'],
        ['projectName', 'project_name'],
        ['projectReference', 'project_reference'],
        ['budgetValue', 'budget_value'],
        ['workspace', 'workspace'],
        ['progressValue', 'progress_value'],
        ['status', 'status'],
        ['projectShortName', 'project_short_name'],

    ]);

    fromJson(json: any): ProjectDenorm {
        const projectDenorm = new ProjectDenorm();
        this.mapping.forEach((value, key, map) => {
            projectDenorm[key] = json[value];
        });

        /**
         * Création des subportfolios
         */
        const subPortfolios = json['sub_portfolios'];
        if (subPortfolios && subPortfolios instanceof Array) {
            const subPorts: SubPortfolio[] = [];
            subPortfolios.forEach(subPort => {
                subPorts.push(this.subPortoflioSerializer.fromJson(subPort));
            });
            projectDenorm.subPortfolios = subPorts;
        }

        return projectDenorm;
    }

    toApi(projectDenorm: ProjectDenorm): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = projectDenorm[key];
        });
        return obj;
    }

}
