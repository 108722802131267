import {Injectable} from '@angular/core';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EdwValidationType, EdwValidationTypeSerializer} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class ValidationTypeService extends EntityService<EdwValidationType> {
    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl,
            'validations',
            new EdwValidationTypeSerializer()
        );
    }

    listFromProject(projectId: string, param): Observable<EdwValidationType[]> {
        return this.http.get(`${this.url}/${this.resource}/${param}/project/${projectId}/validations`)
            .pipe(map(
                (data: any) => this.convertData(data)
            ));
    }
}
