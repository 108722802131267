import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {GovernancePortfolio} from './GovernancePortfolio';

export class GovernancePortfolioSerializer implements EntitySerializer {

    public mapping = new Map([
        ['governanceId', 'governance_id'],
    ]);

    fromJson(json: any): GovernancePortfolio {
        const governancePortfolios = new GovernancePortfolio();
        this.mapping.forEach((value, key, map) => {
            governancePortfolios[key] = json[value];
        });
        return governancePortfolios;
    }

    toApi(governancePortfolios: GovernancePortfolio): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = governancePortfolios[key];
        });
        return obj;
    }

}
