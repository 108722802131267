import {ReferentialItem} from '../referential-item/ReferentialItem';
import {Portfolio} from '../portfolio/Portfolio';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class Referential extends Entity {
    private _name?: string;
    private _scoreName?: string;
    private _description?: string;
    private _type?: string;
    private _referentialItems: ReferentialItem[];
    private _portfolios: Portfolio[];

    constructor() {
        super();
        this._referentialItems = [];
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get scoreName(): string {
        return this._scoreName;
    }

    set scoreName(value: string) {
        this._scoreName = value;
    }


    get referentialItems(): ReferentialItem[] {
        return this._referentialItems;
    }

    set referentialItems(value: ReferentialItem[]) {
        this._referentialItems = value;
    }


    get portfolios(): Portfolio[] {
        return this._portfolios;
    }

    set portfolios(value: Portfolio[]) {
        this._portfolios = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }
}

