import {HumanResourceItem} from './HumanResourceItem';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class HumanResource extends Entity {

    private _intSteerAdr?: number;
    private _intManageAdr?: number;
    private _intPerformAdr?: number;
    private _extSteerAdr?: number;
    private _extManageAdr?: number;
    private _extPerformAdr?: number;
    private _intManDays?: number;
    private _intCosts?: number;
    private _extManDays?: number;
    private _extCosts?: number;
    private _hrItems: HumanResourceItem[];

    constructor() {
        super();
        this._hrItems = [];
    }

    get intSteerAdr(): number {
        return this._intSteerAdr;
    }

    set intSteerAdr(value: number) {
        this._intSteerAdr = value;
    }

    get intManageAdr(): number {
        return this._intManageAdr;
    }

    set intManageAdr(value: number) {
        this._intManageAdr = value;
    }

    get intPerformAdr(): number {
        return this._intPerformAdr;
    }

    set intPerformAdr(value: number) {
        this._intPerformAdr = value;
    }

    get extSteerAdr(): number {
        return this._extSteerAdr;
    }

    set extSteerAdr(value: number) {
        this._extSteerAdr = value;
    }

    get extManageAdr(): number {
        return this._extManageAdr;
    }

    set extManageAdr(value: number) {
        this._extManageAdr = value;
    }

    get extPerformAdr(): number {
        return this._extPerformAdr;
    }

    set extPerformAdr(value: number) {
        this._extPerformAdr = value;
    }

    get intManDays(): number {
        return this._intManDays;
    }

    set intManDays(value: number) {
        this._intManDays = value;
    }

    get intCosts(): number {
        return this._intCosts;
    }

    set intCosts(value: number) {
        this._intCosts = value;
    }

    get extManDays(): number {
        return this._extManDays;
    }

    set extManDays(value: number) {
        this._extManDays = value;
    }

    get extCosts(): number {
        return this._extCosts;
    }

    set extCosts(value: number) {
        this._extCosts = value;
    }

    get hrItems(): HumanResourceItem[] {
        return this._hrItems;
    }

    set hrItems(value: HumanResourceItem[]) {
        this._hrItems = value;
    }
}
