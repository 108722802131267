import {Contact} from '../contact/Contact';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class ContactValidationStep extends Entity {
    private _contact: Contact;
    private _contactInfos: Contact;
    private _priority: number;
    private _sending_date: Date;
    private _subscribe: boolean;


    constructor() {
        super();
    }


    get contact(): Contact {
        return this._contact;
    }

    set contact(value: Contact) {
        this._contact = value;
    }

    get contactInfos(): Contact {
        return this._contactInfos;
    }

    set contactInfos(value: Contact) {
        this._contactInfos = value;
    }

    get priority(): number {
        return this._priority;
    }

    set priority(value: number) {
        this._priority = value;
    }

    get sending_date(): Date {
        return this._sending_date;
    }

    set sending_date(value: Date) {
        this._sending_date = value;
    }

    get subscribe(): boolean {
        return this._subscribe;
    }

    set subscribe(value: boolean) {
        this._subscribe = value;
    }
}
