import {Indicator} from '../indicator/Indicator';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class Classification extends Entity {
    private _type: string;
    private _averageScore?: any;
    private _itQualification?: string;
    private _scoreError: number;
    private _referential?: string;
    private _project?: string;
    private _indicators?: Indicator[];


    constructor() {
        super();
        this._indicators = [];
    }

    /**
     * Methode appelée pour créer directement une Classification Gartner
     * La définition d'une Classification Gartner est fixe, non lié à un Referential
     */
    static createGartner() {
        const classification = new Classification();
        classification.type = 'GARTNER';
        classification.averageScore = 0.0;
        classification.referential = null;
        classification.indicators = Indicator.getGartnerIndicators();

        return classification;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get averageScore(): any {
        return this._averageScore;
    }

    set averageScore(value: any) {
        this._averageScore = value;
    }

    get itQualification(): string {
        return this._itQualification;
    }

    set itQualification(value: string) {
        this._itQualification = value;
    }

    get referential(): string {
        return this._referential;
    }

    set referential(value: string) {
        this._referential = value;
    }

    get project(): string {
        return this._project;
    }

    set project(value: string) {
        this._project = value;
    }

    get indicators(): Indicator[] {
        return this._indicators;
    }

    set indicators(value: Indicator[]) {
        this._indicators = value;
    }

    get scoreError(): number {
        return this._scoreError;
    }

    set scoreError(value: number) {
        this._scoreError = value;
    }
}
