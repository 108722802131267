import {Entity, Picture} from '@edward-software/edw-fwk-angular-lib/models';

export class Contact extends Entity {
    private _title?: string;
    private _firstName?: string;
    private _lastName?: string;
    private _nickname?: string;
    private _phoneNumber?: string;
    private _cellPhoneNumber?: string;
    private _jobTitle?: string;
    private _email?: string;
    private _comment: string;
    private _associatedUser: string;
    private _contactFirstName: string;
    private _contactLastName: string;
    private _contactNickname: string;
    private _contactEmail: string;
    private _contactJobTitle: string;
    private _companyName: string;
    private _contactPicture: Picture;
    private _role: string;
    private _contactId: string;
    private _contactPhoneNumber: string;
    private _contactPictureUrl: string;
    private _status: string;

    constructor() {
        super();
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get nickname(): string {
        return this._nickname;
    }

    set nickname(value: string) {
        this._nickname = value;
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    set phoneNumber(value: string) {
        this._phoneNumber = value;
    }

    get cellPhoneNumber(): string {
        return this._cellPhoneNumber;
    }

    set cellPhoneNumber(value: string) {
        this._cellPhoneNumber = value;
    }

    get jobTitle(): string {
        return this._jobTitle;
    }

    set jobTitle(value: string) {
        this._jobTitle = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
    }

    get associatedUser(): string {
        return this._associatedUser;
    }

    set associatedUser(value: string) {
        this._associatedUser = value;
    }

    toString() {
        return (this.firstName || '') + ' ' + (this.lastName || '');
    }

    get contactFirstName(): string {
        return this._contactFirstName;
    }

    set contactFirstName(value: string) {
        this._contactFirstName = value;
    }


    get contactLastName(): string {
        return this._contactLastName;
    }

    set contactLastName(value: string) {
        this._contactLastName = value;
    }

    get contactNickname(): string {
        return this._contactNickname;
    }

    set contactNickname(value: string) {
        this._contactNickname = value;
    }

    get contactEmail(): string {
        return this._contactEmail;
    }

    set contactEmail(value: string) {
        this._contactEmail = value;
    }

    get role(): string {
        return this._role;
    }

    set role(value: string) {
        this._role = value;
    }

    get contactId(): string {
        return this._contactId;
    }

    set contactId(value: string) {
        this._contactId = value;
    }


    get contactJobTitle(): string {
        return this._contactJobTitle;
    }

    set contactJobTitle(value: string) {
        this._contactJobTitle = value;
    }

    get companyName(): string {
        return this._companyName;
    }

    set companyName(value: string) {
        this._companyName = value;
    }


    get contactPhoneNumber(): string {
        return this._contactPhoneNumber;
    }

    set contactPhoneNumber(value: string) {
        this._contactPhoneNumber = value;
    }

    get contactPicture(): Picture {
        return this._contactPicture;
    }

    set contactPicture(value: Picture) {
        this._contactPicture = value;
    }
    get contactPictureUrl(): string {
        return this._contactPictureUrl;
    }

    set contactPictureUrl(value: string) {
        this._contactPictureUrl = value;
    }
    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }
}
