import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class Project extends Entity {

    private _name?: string;
    private _shortName?: string;
    private _reference?: string;
    private _comment?: string;
    private _longDescription?: string;
    private _description?: string;
    private _type?: string;
    private _locked?: Date;
    private _parent?: any;
    private _roles?: any;
    private _portfolioId?: string;
    private _status: string;
    private _accessProject: string[];
    private _accessIdaList?: string[];

    constructor() {
        super();
        this.type = 'PROJECT';
    }


    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get shortName(): string {
        return this._shortName;
    }

    set shortName(value: string) {
        this._shortName = value;
    }

    get reference(): string {
        return this._reference;
    }

    set reference(value: string) {
        this._reference = value;
    }

    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get locked(): Date {
        return this._locked;
    }

    set locked(value: Date) {
        this._locked = value;
    }

    get parent(): any {
        return this._parent;
    }

    set parent(value: any) {
        this._parent = value;
    }

    get roles(): any {
        return this._roles;
    }

    set roles(value: any) {
        this._roles = value;
    }

    get portfolioId(): string {
        return this._portfolioId;
    }

    set portfolioId(value: string) {
        this._portfolioId = value;
    }

    get longDescription(): string {
        return this._longDescription;
    }

    set longDescription(value: string) {
        this._longDescription = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }


    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    get accessProject(): string[] {
        return this._accessProject;
    }

    set accessProject(value: string[]) {
        this._accessProject = value;
    }

    get accessIdaList(): string[] {
        return this._accessIdaList;
    }

    set accessIdaList(value: string[]) {
        this._accessIdaList = value;
    }
}
