import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class Indicator extends Entity {
    private _score?: number;
    private _weight?: number;
    private _gartnerItem?: string;
    private _referentialItem?: string;

    constructor() {
        super();
        this.score = 0;
        this.weight = 1;
        this.gartnerItem = null;
    }

    get score(): number {
        return this._score;
    }

    set score(value: number) {
        this._score = value;
    }

    get weight(): number {
        return this._weight;
    }

    set weight(value: number) {
        this._weight = value;
    }

    get gartnerItem(): string {
        return this._gartnerItem;
    }

    set gartnerItem(value: string) {
        this._gartnerItem = value;
    }

    get referentialItem(): string {
        return this._referentialItem;
    }

    set referentialItem(value: string) {
        this._referentialItem = value;
    }

    /**
     * Retourne les indicators nécessaires à la création d'une classification Gartner
     */
    static getGartnerIndicators() {
        const indicators: Indicator[] = [];

        const indicator1 = new Indicator();
        indicator1.referentialItem = null;
        indicator1.gartnerItem = 'TRANSFORMATIONAL';
        indicator1.score = 0;
        indicator1._weight = 1;
        indicators.push(indicator1);

        const indicator2 = new Indicator();
        indicator2.referentialItem = null;
        indicator2.gartnerItem = 'OUTCOME_DRIVEN';
        indicator2.score = 0;
        indicator2._weight = 1;
        indicators.push(indicator2);

        const indicator3 = new Indicator();
        indicator3.referentialItem = null;
        indicator3.gartnerItem = 'POLICY_DRIVEN';
        indicator3.score = 0;
        indicator3._weight = 1;
        indicators.push(indicator3);

        const indicator4 = new Indicator();
        indicator4.referentialItem = null;
        indicator4.gartnerItem = 'CHANGE_ORIENTED';
        indicator4.score = 0;
        indicator4._weight = 1;
        indicators.push(indicator4);

        const indicator5 = new Indicator();
        indicator5.referentialItem = null;
        indicator5.gartnerItem = 'BOTTOM_UP';
        indicator5.score = 0;
        indicator5._weight = 1;
        indicators.push(indicator5);

        return indicators;
    }
}
