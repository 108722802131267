import {HumanResource} from './HumanResource';
import {HumanResourceItemSerializer} from './HumanResourceItemSerializer';
import {HumanResourceItem} from './HumanResourceItem';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class HumanResourceSerializer implements EntitySerializer {

    hrItemSerializer = new HumanResourceItemSerializer();


    public mapping = new Map([
        ['id', 'id'],
        ['intSteerAdr', 'int_steer_adr'],
        ['intManageAdr', 'int_manage_adr'],
        ['intPerformAdr', 'int_perform_adr'],
        ['extSteerAdr', 'ext_steer_adr'],
        ['extManageAdr', 'ext_manage_adr'],
        ['extPerformAdr', 'ext_perform_adr'],
        ['intManDays', 'int_man_days'],
        ['intCosts', 'int_costs'],
        ['extManDays', 'ext_man_days'],
        ['extCosts', 'ext_costs']
    ]);

    fromJson(json: any): HumanResource {
        const humanResource = new HumanResource();
        this.mapping.forEach((value, key, map) => {
            humanResource[key] = json[value];
        });

        /**
         * Création des items
         */
        const items = json['hr_items'];
        if (items && items instanceof Array) {
            const hrItems: HumanResourceItem[] = [];
            items.forEach(it => {
                hrItems.push(this.hrItemSerializer.fromJson(it));
            });
            humanResource.hrItems = hrItems;
        }

        return humanResource;
    }

    toApi(humanResource: HumanResource): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = humanResource[key];
        });
        return obj;
    }

}
