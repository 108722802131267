import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SubPortfolio} from '../../../models/sub-portfolio/SubPortfolio';
import {SubPortfolioSerializer} from '../../../models/sub-portfolio/SubPortfolioSerializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';
import {ListResponse} from '@edward-software/edw-fwk-angular-lib/models';


@Injectable({
    providedIn: 'root'
})
export class SubPortfolioService extends EntityService<SubPortfolio> {

    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl,
            'sub_portfolios',
            new SubPortfolioSerializer()
        );
    }

    addSubPortfolio(id: string, name: string, color: string) {
        return this.http.post(`${this.url}/portfolios/${id}/sub_portfolios`,
            {
                name: name,
                color: color
            },
            this.options
        );
    }

    public listFromPortfolio(portfolioId): Observable<SubPortfolio[]> {
        return this.http.get<SubPortfolio[]>(`${this.url}/portfolios/${portfolioId}/${this.resource}`)
            .pipe(map((data: any) => this.convertData(data)));
    }

    public listSubPortfolioFromProject(projectId): Observable<ListResponse<SubPortfolio>> {
        return this.http.get<SubPortfolio[]>(`${this.url}/projects/${projectId}/sub_portfolios`)
            .pipe(map((data: any) => new ListResponse(this.convertData(data.data), this.paginationMetaSerializer.fromJson(data.meta))));
    }

    public createFromPortfolio(id, subPortfolio: SubPortfolio): Observable<SubPortfolio> {
        return this.http.post<SubPortfolio>(this.url + '/portfolios/' + id + '/sub_portfolios', this.serializer.toApi(subPortfolio), this.options)
            .pipe(map(newSubPortfolio => {
                return this.serializer.fromJson(newSubPortfolio) as SubPortfolio;
            }));
    }

    public deleteSubPortfolioProject(subPortfolioId: string, projectId: string) {
        return this.http.delete(`${this.url}/${this.resource}/${subPortfolioId}/projects/${projectId}`);
    }

    public updatePositionList(ids: string[], portfolioId: string) {
        return this.http.put(`${this.url}/portfolios/${portfolioId}/${this.resource}/position`, {sub_portfolios: ids}, this.options)
            .pipe(map(gains => gains));
    }

    public listFromProject(projectId): Observable<ListResponse<SubPortfolio>> {
        return this.http.get(`${this.url}/projects/${projectId}/${this.resource}`)
            .pipe(map((data: any) => {
                return new ListResponse(
                    this.convertData(data.data),
                    this.paginationMetaSerializer.fromJson(data.meta)
                );
            }));
    }

}
