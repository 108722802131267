import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class HumanResourceItem extends Entity {

    private _name: string;
    private _type: string;
    private _steerPlanValue?: number;
    private _managePlanValue?: number;
    private _performPlanValue?: number;
    private _steerBuildValue?: number;
    private _manageBuildValue?: number;
    private _performBuildValue?: number;
    private _steerDeployValue?: number;
    private _manageDeployValue?: number;
    private _performDeployValue?: number;

    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get steerPlanValue(): number {
        return this._steerPlanValue;
    }

    set steerPlanValue(value: number) {
        this._steerPlanValue = value;
    }

    get managePlanValue(): number {
        return this._managePlanValue;
    }

    set managePlanValue(value: number) {
        this._managePlanValue = value;
    }

    get performPlanValue(): number {
        return this._performPlanValue;
    }

    set performPlanValue(value: number) {
        this._performPlanValue = value;
    }

    get steerBuildValue(): number {
        return this._steerBuildValue;
    }

    set steerBuildValue(value: number) {
        this._steerBuildValue = value;
    }

    get manageBuildValue(): number {
        return this._manageBuildValue;
    }

    set manageBuildValue(value: number) {
        this._manageBuildValue = value;
    }

    get performBuildValue(): number {
        return this._performBuildValue;
    }

    set performBuildValue(value: number) {
        this._performBuildValue = value;
    }

    get steerDeployValue(): number {
        return this._steerDeployValue;
    }

    set steerDeployValue(value: number) {
        this._steerDeployValue = value;
    }

    get manageDeployValue(): number {
        return this._manageDeployValue;
    }

    set manageDeployValue(value: number) {
        this._manageDeployValue = value;
    }

    get performDeployValue(): number {
        return this._performDeployValue;
    }

    set performDeployValue(value: number) {
        this._performDeployValue = value;
    }
}
