import {Referential} from './Referential';
import {Portfolio} from '../portfolio/Portfolio';
import {PortfolioSerializer} from '../portfolio/PortfolioSerializer';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ReferentialSerializer implements EntitySerializer {

    private portfolioSerializer = new PortfolioSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['description', 'description'],
        ['type', 'type'],
        ['scoreName', 'score_name']
    ]);

    fromJson(json: any): Referential {
        const referential = new Referential();
        this.mapping.forEach((value, key, map) => {
            referential[key] = json[value];
        });

        /**
         * Création des indicators
         */
        const portfolios = json['portfolios'];
        if (portfolios && portfolios instanceof Array) {
            const ports: Portfolio[] = [];
            portfolios.forEach(port => {
                ports.push(this.portfolioSerializer.fromJson(port));
            });
            referential.portfolios = ports;
        }

        return referential;
    }

    toApi(referential: Referential): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = referential[key];
        });
        return obj;
    }

}
