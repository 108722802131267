import {ValidationStepResult} from './ValidationStepResult';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ValidationStepResultSerializer implements EntitySerializer {


    public mapping = new Map([
        ['result', 'result'],
        ['comment', 'comment'],

    ]);


    fromJson(json: any): ValidationStepResult {
        const validationStepResult = new ValidationStepResult();
        this.mapping.forEach((value, key, map) => {
            validationStepResult[key] = json[value];
        });
        return validationStepResult;
    }

    toApi(validationStepResult: ValidationStepResult): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validationStepResult[key];
        });

        return obj;
    }
}
