import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwValidation, EdwValidationSerializer, ListResponse, QueryOption} from '@edward-software/edw-fwk-angular-lib/models';
import {ValidationTypeSerializer} from '../../../models/workflow/ValidationTypeSerializer';

@Injectable({
    providedIn: 'root'
})

export class ValidationService extends EntityService<EdwValidation> {
    private validationCustomSerializer = new ValidationTypeSerializer();
    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl,
            'validations',
            // new ValidationSerializer()
            new EdwValidationSerializer()
        );
    }

    listFromWorkflow(workflowId: string): Observable<ListResponse<EdwValidation>> {
        return this.http.get(`${this.url}/workflows/${workflowId}/${this.resource}?get_validation_steps=1&get_custom=1`)
            .pipe(map((data: any) => {
                    return new ListResponse(
                        this.convertData(data.data),
                        this.paginationMetaSerializer.fromJson(data.meta));
                }
            ));
    }
    listFromWorkspace(queryOption: QueryOption) {
        return this.http.get(`${this.url}/${this.resource}?get_steps=1&get_contact_steps=1&${queryOption.toQueryString()}`)
            .pipe(map((data: any) => {
                return this.convertData(data.data);
            }));
    }
    updateValidationStatus(validationId: string, status: string) {
        return this.http.put(`${this.url}/${this.resource}/${validationId}/status`,
            {
                status: status
            },
            this.options
        )
            .pipe(map(
                resp => resp
            ));
    }


}
