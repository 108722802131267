import {BCaseBudget} from './BCaseBudget';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class BCaseBudgetSerializer implements EntitySerializer {
    public mapping = new Map([
        ['id', 'id'],
        ['budget', 'budget'],
        ['capex', 'capex'],
        ['opex', 'opex'],
        ['roi', 'roi'],

    ]);

    fromJson(json: any): BCaseBudget {
        if (!json) {
            return new BCaseBudget();
        }
        const bCaseBudget = new BCaseBudget();
        this.mapping.forEach((value, key, map) => {
            bCaseBudget[key] = json[value];
        });
        return bCaseBudget;
    }

    toApi(bCaseBudget: BCaseBudget): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = bCaseBudget[key];
        });
        return obj;
    }
}
