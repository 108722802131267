import {Project} from './Project';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ProjectSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['shortName', 'short_name'],
        ['reference', 'reference'],
        ['comment', 'comment'],
        ['longDescription', 'long_description'],
        ['description', 'description'],
        ['type', 'type'],
        ['locked', 'locked'],
        ['parent', 'parent'],
        ['roles', 'roles'],
        // ['portfolioId', 'portfolio_id'],
    ]);

    fromJson(json: any): Project {
        const project = new Project();
        this.mapping.forEach((value, key, map) => {
            project[key] = json[value];
        });
        return project;
    }

    toApi(project: Project): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = project[key];
        });
        return obj;
    }
}
