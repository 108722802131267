import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BCaseBudgetSerializer} from '../../../models/bcase-budget/BCaseBudgetSerializer';
import {BCaseBudget} from '../../../models/bcase-budget/BCaseBudget';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';

@Injectable({
    providedIn: 'root'
})
export class BcaseBudgetService extends EntityService<BCaseBudget> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl,
            'bcase_budgets',
            new BCaseBudgetSerializer()
        );
    }

    public createFromProject(projectId, bCaseBudget: BCaseBudget): Observable<BCaseBudget> {
        return this.http.post<BCaseBudget>(this.url + '/projects/' + projectId + '/' + this.resource, this.serializer.toApi(bCaseBudget), this.options)
            .pipe(map(newBCaseBudget => {
                return this.serializer.fromJson(newBCaseBudget) as BCaseBudget;
            }));
    }

    public readFromProject(projectId): Observable<BCaseBudget> {
        return this.http.get<BCaseBudget>(this.url + '/projects/' + projectId + '/' + this.resource)
            .pipe(map(bCaseBudget => {
                return this.serializer.fromJson(bCaseBudget) as BCaseBudget;
            }));
    }
}
