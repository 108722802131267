import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class ContactPortfolios extends Entity {
    private _contactId: string;
    private _role: string;

    constructor() {
        super();

    }
    get contactId(): string {
        return this._contactId;
    }

    set contactId(value: string) {
        this._contactId = value;
    }
    get role(): string {
        return this._role;
    }

    set role(value: string) {
        this._role = value;
    }


}
