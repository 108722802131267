import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Contact} from '../../../models/contact/Contact';

import {map} from 'rxjs/operators';
import {ContactSerializer} from '../../../models/contact/ContactSerializer';
import {Observable} from 'rxjs';
import {Project} from '../../../models/project/Project';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwContact, ListResponse, QueryOption} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class ContactService extends EntityService<EdwContact> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAdbUrl,
            'contacts',
            new ContactSerializer()
        );
    }

    /**
     * GESTION du PPMOwner
     */

    public readPPMOwner(projectId: string): Observable<Contact> {
        return this.http.get<Contact>(JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl + '/projects/' + projectId + '/owner')
            .pipe(map(contact => {
                    return this.serializer.fromJson(contact) as Contact;
                }
            ));
    }

    /**
     * Permet de récupérer un contact depuis son UserId
     * Cela va permettre de gérer les accès vers les projets
     */
    getContactByUserId(userId: string) {
        return this.http.get<Contact>(JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAdbUrl + '/contacts?get_by_user=' + userId)
            .pipe(map(contact => {
                    return this.serializer.fromJson(contact) as Contact;
                }
            ));
    }

    /**
     * Met à jour le owner d'un project
     */
    public updateProjectOwner(projectId: string, contactId: string): Observable<Contact> {
        return this.http.post<Project[]>(JSON.parse(localStorage.getItem('ENV_CONFIG')).apiProjectUrl + '/egd_tools/contact_associations/projects/' + projectId + '/contacts/' + contactId,
            {'role': 'PPM_PROJECT_OWNER'})
            .pipe(map(contact => {
                return this.serializer.fromJson(contact) as Contact;
            }));
    }

    /**
     * Met à jour le owner d'une change request
     */
    public updateChangeRequestOwner(projectId: string, contactId: string): Observable<Contact> {
        return this.http.post<Project[]>(JSON.parse(localStorage.getItem('ENV_CONFIG')).apiProjectUrl + '/egd_tools/contact_associations/change_requests/' + projectId + '/contacts/' + contactId,
            {'role': 'PPM_CHANGE_REQUEST_OWNER'})
            .pipe(map(contact => {
                return this.serializer.fromJson(contact) as Contact;
            }));
    }

    /**
     * GET PORTFOLIO CONTACT LIST
     */
    public getPortfolioContact(portfolioId, queryOption: QueryOption) {
        return this.http.get<Contact>(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/portfolios/${portfolioId}/contacts?${queryOption.toQueryString()}`)
            .pipe(map((data: any) => {
                return new ListResponse(
                    this.convertData(data.data),
                    this.paginationMetaSerializer.fromJson(data.meta)
                );
            }));

    }


    /**
     * ADD PORTFOLIO CONTACT LIST
     */
    public addPortfolioContact(portfolioId: string, contactId: string, role: string, queryOption: QueryOption): Observable<Contact> {
        return this.http.post<Project[]>(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/portfolios/${portfolioId}/contacts/${contactId}`,
            {
                'role': role,
            })
            .pipe(map(contact => {
                return this.serializer.fromJson(contact) as Contact;
            }));
    }

    editRole(portfolioId: string, contactId: string, role: string): Observable<Contact> {
        return this.http
            .put(
                `${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/portfolios/${portfolioId}/contacts/${contactId}/role`,
                {
                    role: role
                },
                this.options
            )
            .pipe(map(data => this.serializer.fromJson(data) as Contact));
    }

    deleteContactPortfolio(portfolioId: string, contact: Contact) {
        return this.http.delete(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl}/egd_tools/contact_associations/portfolios/${portfolioId}/contacts/${contact.contactId}/${contact.role}`);
    }

}
