import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class GovernancePortfolio extends Entity {
    private _governanceId: string;

    constructor() {
        super();

    }
    get governanceId(): string {
        return this._governanceId;
    }

    set governanceId(value: string) {
        this._governanceId = value;
    }
}
