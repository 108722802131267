import {Injectable} from '@angular/core';
import {Portfolio} from 'src/app/models/portfolio/Portfolio';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {filter, map} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Referential} from '../../../models/referential/Referential';
import {PortfolioSerializer} from '../../../models/portfolio/PortfolioSerializer';
import {ReferentialSerializer} from '../../../models/referential/ReferentialSerializer';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';


@Injectable({
    providedIn: 'root'
})
export class PortfolioService extends EntityService<Portfolio> {

    private referentiaLSerializer = new ReferentialSerializer();

    public selectedPortfolio: BehaviorSubject<Portfolio> = new BehaviorSubject<Portfolio>(null);
    public portfolioList: BehaviorSubject<Portfolio[]> = new BehaviorSubject<Portfolio[]>(null);

    public newPortfolio: Subject<Portfolio> = new Subject<Portfolio>();
    public deletedPortfolioId: Subject<String> = new Subject<String>();

    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    currentUrl: string;

    constructor(private http: HttpClient,
                private router: Router,
                private route: ActivatedRoute,
    ) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl,
            'portfolios',
            new PortfolioSerializer()
        );
        this.getPortfolio();
        /**
         * A chaque fin de Navigation, on regarde si un des paramètres de l'URL est le portfolioId
         * Si c'est le cas, on récupère le portfolio et on l'émet à travers un event dans le selectedPortfolio pour l'envoyer
         * à tous les components qui ont subscribe() à selectedPortfolio
         */
        router.events
            .pipe(
                filter(e => e instanceof NavigationEnd))
            .forEach(e => {
                this.getPortfolio();
            });
    }

    /****************
     * Récupération du Portfolio dans l'URL
     ***************/
    getPortfolio() {
        const portfolioRoute = this.route.root.snapshot?.firstChild?.firstChild?.firstChild?.firstChild;
        const govRoute = this.route.root.snapshot?.firstChild?.firstChild?.firstChild?.firstChild?.firstChild;
        let govKeys = [];
        if (govRoute) {
            govKeys = Object.keys(govRoute.params);
        }
        if (portfolioRoute != null) {
            const portfolioId = portfolioRoute.paramMap.get('portfolioId');
            if (portfolioId !== null && typeof (portfolioId) !== 'undefined' && portfolioId.length > 0 && this.selectedPortfolio) {
                let params = '';
                /**
                 * En récupérant le portfolio, on peut également récupérer l'id de l'idaList associé, et on a besoin de cet id dans la page
                 * de détail d'un meeting (pour le compte-rendu) et dans la page de la liste des idas
                 */
                if ((govKeys.length >= 1 && govKeys.includes('meetingId')) || (this.router.url && this.router.url.split('/').pop() === 'action')) {
                    params = 'get_ida_list=1';
                }
                this.read(portfolioId, params)
                    .subscribe(portfolio => {
                        this.selectedPortfolio.next(portfolio);
                    }, error => {
                        /**
                         * Si un utilisateur n'a pas de rôle dans le portfolio et qu'il essaye d'accéder à la page des comités ou la page de meeting d'un portfolio,
                         * on le redirige vers la page Mon Bureau
                         */
                        if (error.code === 401 && error.message === 'userNotAuthorized' && govKeys && govKeys.length >= 1 && !govKeys.includes('governanceId') && !govKeys.includes('meetingId') ) {
                            this.router.navigate(['/']);
                        }
                    });
            } else {
                this.selectedPortfolio.next(null);
            }
        }
    }

    /**
     * Récupération de l'idaList d'un portfolio
     */
    getIdaListPortfolio(portfolioId: string) {
        return this.http.get(`${this.url}/egd_tools/ida_list_associations/portfolios/${portfolioId}/ida_lists`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    createIdaListPortfolio(portfolioId: string) {
        return this.http.post(`${this.url}/portfolios/${portfolioId}/ida_lists`,
            null,
            this.options
        ).pipe(map(
                resp => resp
            ));
    }

    /***************
     * GESTION DES REFERENTIALS
     ***************/

    public getPortfolioReferentials(id) {
        return this.http.get<Referential[]>(this.url + '/portfolios/' + id + '/referentials')
            .pipe(map(referentials => {
                const refs: Referential[] = [];
                referentials.forEach(ref => {
                    refs.push(this.referentiaLSerializer.fromJson(ref));
                });
                return refs;
            }));
    }


    editCurrency(portfolioId: string, currency: string): Observable<Portfolio> {
        return this.http
            .put(
                `${this.url}/${this.resource}/${portfolioId}`,
                {
                    currency: currency
                },
                this.options
            )
            .pipe(map(data => this.serializer.fromJson(data) as Portfolio));
    }

    /***************************************
     * Gestion de l'importance Schéma
     **************************************/
    getImportanceStructure() {
        return [
            {
                id: 'profitability',
                label: 'Business process profitability',
                score: 0,
                weight: 1
            },
            {
                id: 'supportCosts',
                label: 'Support costs',
                score: 0,
                weight: 1
            },
            {
                id: 'internalImpact',
                label: 'Internal impact',
                score: 0,
                weight: 1
            }, {
                id: 'externalImpact',
                label: 'External impact',
                score: 0,
                weight: 1
            }, {
                id: 'projectImpact',
                label: 'Project impact',
                score: 0,
                weight: 1
            }];

    }

    // TODO ALLER DANS portfolio_cr_infos
    updateCrInfos(portfolioId: string, importanceSchema: any, urgencySchema: any) {
        return this.http.put(`${this.url}/${this.resource}/${portfolioId}/portfolio_cr_infos`,
            {
                importance_schema: importanceSchema,
                uegency_schema: urgencySchema
            })
            .pipe(map(
                resp => resp
            ));
    }

    /*********************
     * RECUPERATION DES DONNEES
     *******************/

    readFromProject(projectId: string): Observable<Portfolio> {
        return this.http.get(`${this.url}/projects/${projectId}/${this.resource}`)
            .pipe(map(
                resp => this.serializer.fromJson(resp[0]) as Portfolio
            ));
    }

    readFromChangeRequest(changeRequestId: string): Observable<Portfolio> {
        return this.http.get(`${this.url}/change_requests/${changeRequestId}/${this.resource}`)
            .pipe(map(
                resp => this.serializer.fromJson(resp) as Portfolio
            ));
    }


    /**************************
     * Quand une nouvelle Portfolioe est cree,
     * on l'envoie aux observers
     *************************/

    setNewPortfolio(portfolio: Portfolio) {
        this.newPortfolio.next(portfolio);
    }


    setDeletedPortfolio(id: String) {
        this.deletedPortfolioId.next(id);
    }

    setPortfolioList(portfolios: Portfolio[]) {
        this.portfolioList.next(portfolios);
    }
    /*********************
     * TRAITEMENT des données
     *******************/
    /**
     * Permet de déplacer un projet vers un autre portfolio
     */
    moveProjectPortfolio(portfolioId: string, projectId: string, action: string) {
        return this.http.post(`${this.url}/${this.resource}/${portfolioId}/projects/${projectId}`,
            {'action': action})
            .pipe(map(portfolio => {
                return this.serializer.fromJson(portfolio) as Portfolio;
            }));
    }

}
