import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {WorkflowService} from '../../../../../../providers/services/workflow/workflow.service';
import {Workflow} from '../../../../../../models/workflow/Workflow';
import {Portfolio} from '../../../../../../models/portfolio/Portfolio';
import {forkJoin, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SelectItem} from 'primeng/api';
import {EdwToolsToastService, EdwToolsWorkspaceService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwOverlayService} from '@edward-software/edw-fwk-angular-lib/overlay';
import {EdwHttpError} from '@edward-software/edw-fwk-angular-lib/models';
import {ProjectService} from '../../../../../../providers/services/project/project.service';

@Component({
    selector: 'app-new-project-validation-modal',
    templateUrl: './new-project-validation-modal.component.html',
    styleUrls: ['./new-project-validation-modal.component.scss']
})
export class NewProjectValidationModalComponent implements OnInit, OnDestroy {

    projectId: string;
    isSubscribe = true;

    workflow: Workflow;
    workflows: Workflow[];
    workflowsLoading = true;

    portfolio: Portfolio;

    submitDisabled = false;

    locales: SelectItem[];
    locale: string;
    currentProjectSub: Subscription;
    modelName: string;

    constructor(public ref: DynamicDialogRef,
                private workspaceService: EdwToolsWorkspaceService,
                public dialogConfig: DynamicDialogConfig,
                private projectService: ProjectService,
                public overlayService: EdwOverlayService,
                private workflowService: WorkflowService,
                private toastService: EdwToolsToastService,
                private route: ActivatedRoute,
                private router: Router) {

        this.locales = [
            {label: 'FR', value: 'FR'},
            {label: 'EN', value: 'EN'}
        ];

        this.currentProjectSub = this.projectService.currentProject
            .subscribe((project) => {
                this.modelName = 'validation ' + project.name;
            });
    }

    ngOnInit() {
        this.projectId = this.dialogConfig.data.projectId;
        this.portfolio = this.dialogConfig.data.selectedPortfolio;
        this.getWorkflows();
    }

    /***********************************
     * RECUPERATION des données
     ***********************************/

    /**
     * Récupère les workflows
     */
    getWorkflows() {
        this.workflows = [];
        this.workflowsLoading = true;
        this.workflowService.listFromPortfolio(this.portfolio.id)
            .subscribe(resp => {
                    const workflowIds = resp;
                    if (workflowIds && workflowIds instanceof Array && workflowIds.length > 0) {
                        const requests = [];
                        workflowIds.forEach(workflow => {
                            requests.push(this.workflowService.read(workflow.id));
                        });
                        forkJoin(requests)
                            .subscribe(
                                (respWorkflow: Workflow[]) => {
                                    this.workflowsLoading = false;
                                    this.workflows = respWorkflow;
                                },
                                (error: EdwHttpError) => {
                                    this.workflowsLoading = false;
                                    if (error.message !== 'worfklowNotFound') {
                                        this.toastService.sendEdwHttpError(error);
                                    }
                                }
                            );
                    } else {
                        this.workflowsLoading = false;
                    }
                },
                (error: EdwHttpError) => {
                    this.toastService.sendEdwHttpError(error);
                });
    }

    /***********************************
     * SAUVEGARDE
     ***********************************/

    save(el: HTMLDivElement) {
        const ref = this.overlayService.open(null, {
            attachedTo: el,
            isConfirm: true,
            confirmDanger: false,
            confirmRejectButtonLabel: 'global.cancel',
            confirmAcceptButtonLabel: 'global.confirm',
            confirmTitle: 'menu-navbar.workflow.validation.confirm-launch.title',
            confirmMessage: 'menu-navbar.workflow.validation.confirm-launch.message'
        });

        ref.onClose.subscribe(confirm => {
            if (confirm) {
                this.submitDisabled = true;
                this.workflowService.launchProject(this.workflow.id, this.projectId, this.isSubscribe, true, this.locale, this.modelName)
                    .subscribe(
                        resp => {
                            this.ref.close(true);
                            this.submitDisabled = false;
                            this.toastService.sendSuccess('menu-navbar.workflow.validation.toast.success.summary.launched', 'menu-navbar.workflow.validation.toast.success.details.launched');
                        },
                        (error: EdwHttpError) => {
                            if (error.code === 401 && error.message === 'userNotAuthorized') {
                                this.toastService.sendError('global-toast.error.user-not-authorized', 'launch-project-modal.error.not-authorized.message');
                            } else if (error.code === 500 && error.message === 'atLeastOneStepShouldBeDefined') {
                                this.toastService.sendError('launch-validation.no-step.error', 'launch-validation.no-step.error.message');
                            } else {
                                this.toastService.sendEdwHttpError(error);
                            }
                            this.submitDisabled = false;
                        }
                    );
            }
        });
    }

    /***********************************
     * TRAITEMENT
     ***********************************/

    close() {
        if (!this.submitDisabled) {
            this.ref.close();
        }
    }

    addLocale(event) {
        this.locale = event.value;
    }


    /***********************************
     * NAVIGATION
     ***********************************/

    navigateToPortfolioInformation() {
        this.close();
        this.router.navigate(['/w', this.workspaceService.getSystemName(), 'portfolio', this.portfolio.id, 'information']);
    }
    /***********************************************************************
     * ON DESTROY
     ***********************************************************************/
    ngOnDestroy(): void {
        this.currentProjectSub.unsubscribe();
    }
}
