import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwValidationStep, EdwValidationStepSerializer} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})

export class ValidationStepService extends EntityService<EdwValidationStep> {
    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl,
            'validation_steps',
            new EdwValidationStepSerializer()
        );
    }

    /**
     * Récupération des validationSteps à partir d'un Validation
     */
    listFromValidation(validationId: string): Observable<EdwValidationStep[]> {
        return this.http.get(`${this.url}/validations/${validationId}/${this.resource}?get_contact_steps=1`)
            .pipe(map((data: any) => this.convertData(data)));
    }
}


