import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class ChangeImpact extends Entity {
    private _type?: string;
    private _content?: string;
    private _position?: number;
    private _rating?: number;

    constructor() {
        super();
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get content(): string {
        return this._content;
    }

    set content(value: string) {
        this._content = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get rating(): number {
        return this._rating;
    }

    set rating(value: number) {
        this._rating = value;
    }

    toString() {
        return this._content;
    }
}
