import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
/**
 * Liste des modules Primeng
 */
import {ToastModule} from 'primeng/toast';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CustomTranslateLoader} from './providers/translate-loader/CustomTranslateLoader';
import {NewProjectValidationModalComponent} from './components/commons/partials/menu-navbar/modals/new-project-validation-modal/new-project-validation-modal.component';
/**
 * NgTools
 */
import {ValidationProjectModalComponent} from './components/commons/partials/menu-navbar/modals/validation-project-modal/validation-project-modal.component';
import {RulesStatusModalComponent} from './components/commons/partials/menu-navbar/modals/rules-status-modal/rules-status-modal.component';

import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from '@angular/common';
import {ProjectThreadNavbarTemplateComponent} from './components/commons/partials/thread-navbar/project-thread-navbar-template/project-thread-navbar-template.component';
import {IntercomModule} from 'ng-intercom';
import {ValidationStepResultOverlayComponent} from './components/commons/partials/menu-navbar/modals/validation-project-modal/validation-step-result-overlay/validation-step-result-overlay.component';
import {EdwMissingTranslationHandler, EdwToolsLocaleService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EdwFwkAngularLibModule} from '@edward-software/edw-fwk-angular-lib/api';
import {RouterModule} from '@angular/router';
import {FullCalendarModule} from '@fullcalendar/angular';
import {environment} from '../environments/environment';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastModule,
        RouterModule,
        EdwFwkAngularLibModule.forRoot(environment),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [HttpClient]
            },
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: EdwMissingTranslationHandler}
        }),
        IntercomModule.forRoot({
            appId: 'INITIALISATION',
            updateOnRouterChange: true
        }),
        HttpClientModule,
        AppRoutingModule,
        FullCalendarModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            deps: [EdwToolsLocaleService],
            useFactory: (LocaleService: { locale: string }) => LocaleService.locale
        }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        NewProjectValidationModalComponent,
        ValidationProjectModalComponent,
        RulesStatusModalComponent,
        ProjectThreadNavbarTemplateComponent,
        ValidationStepResultOverlayComponent
    ],
    exports: [
    ]
})
export class AppModule {
}

registerLocaleData(localeEn);
registerLocaleData(localeFr);
