import {ContactValidationStep} from './ContactValidationStep';
import {ContactSerializer} from '../contact/ContactSerializer';
import {Contact} from '../contact/Contact';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ContactValidationStepSerializer implements EntitySerializer {

    private contactSerializer = new ContactSerializer();

    public mapping = new Map([
        ['priority', 'priority'],
        ['sendingDate', 'sending_date'],
        ['subscribe', 'subscribe'],
        ['contactId', 'contact_id']
    ]);

    fromJson(json: any): ContactValidationStep {
        const contactValidationStep = new ContactValidationStep();
        this.mapping.forEach((value, key, map) => {
            contactValidationStep[key] = json[value];
        });

        if (json['contact_id'] && json['contact_infos']) {
            let contact = new Contact();
            contact = this.contactSerializer.fromJson(json['contact_infos']);
            contact.id = json['contact_id'];
            contactValidationStep.contact = contact;
        }
        return contactValidationStep;
    }

    toApi(contactValidationStep: ContactValidationStep): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = contactValidationStep[key];
        });
        return obj;
    }
}
