import {ChangeImpact} from './ChangeImpact';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class ChangeImpactSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['content', 'content'],
        ['position', 'position'],
        ['type', 'type'],
        ['rating', 'rating']
    ]);

    fromJson(json: any): ChangeImpact {
        const changeImpact = new ChangeImpact();
        this.mapping.forEach((value, key, map) => {
            changeImpact[key] = json[value];
        });
        return changeImpact;
    }

    toApi(changeImpact: ChangeImpact): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = changeImpact[key];
        });
        return obj;
    }

}
