import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

export class OrgUnit extends Entity {

    private _name: string;
    private _color: string;
    private _portfolioId?: string;


    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get color(): string {
        return this._color;
    }

    set color(value: string) {
        this._color = value;
    }

    get portfolioId(): string {
        return this._portfolioId;
    }

    set portfolioId(value: string) {
        this._portfolioId = value;
    }
}
